<template>
  <div class="history-page">
    <div class="container">
      <div class="content">
        <!-- 左侧板块 -->
        <div class="left-panel">
          <h2>最近分析的用户</h2>
          <!-- 右侧搜索框 -->
          <div class="search-container">
            <input v-model="searchQuery" type="text" placeholder="搜索用户昵称" class="search-input"
              @input="fetchFilteredData" />
          </div>

          <div v-for="(user, index) in users" :key="user.user_id" class="user-card" @mouseenter="hoverCard(true, index)"
            @mouseleave="hoverCard(false, index)" @click="handleUserClick(user)">
            <!-- 根据 status 显示不同头像和名称 -->
            <img v-if="user.status === '进行中'" :src="require('@/assets/loading-icon.gif')" alt="Loading"
              class="avatar" />
            <img v-if="user.status === '失败'" :src="require('@/assets/failed-icon.png')" alt="Error" class="avatar" />
            <img v-if="user.status === '已完成'"
              :src="user.user_data.profile_picture || require('@/assets/default-avatar.gif')" alt="User Avatar"
              class="avatar" />


            <div class="user-info">
              <!-- 根据状态显示名称，并对 task_id 进行长度判断 -->
              <div class="username">
                {{
                  user.status === '进行中'
                    ? '进行中 ' + truncateTaskId(user.task_id)
                    : user.status === '失败'
                      ? '失败 ' + truncateTaskId(user.task_id)
                      : truncateTaskId(user.user_data.nickname)
                }}
              </div>
              <div class="last-visit">生成时间：{{ user.create_time }}</div>
            </div>
          </div>

          <!-- 左侧分页 -->
          <div class="pagination">
            <el-pagination v-if="totalPagesLeft > 1" :current-page="currentPageLeft" :page-size="10"
              :total="totalItemsLeft" @current-change="handleLeftPageChange" layout="prev, pager, next">
            </el-pagination>
          </div>
        </div>

        <!-- 右侧板块 -->
        <div class="right-panel">
          <h2>文案历史记录</h2>
          <!-- 右侧搜索框 -->
          <div class="search-container">
            <input v-model="titleSearchQuery" type="text" placeholder="搜索文案标题" class="search-input"
              @input="fetchFilteredHistoryData" />
          </div>
          <div v-for="(item, index) in historyItems" :key="index" class="history-item"
            :class="{ 'hovered': hoverIndex === index }" @click="showContent(item)"
            @mouseenter="hoverHistory(true, index)" @mouseleave="hoverHistory(false, index)">
            <div class="item-header">
              <span class="item-tag">模仿文案</span>
              <span class="item-time">{{ item.create_time }}</span>
              <span class="item-username">{{ item.nickname }}</span>
            </div>
            <div class="item-content">标题: {{ item.title }}内容: {{ removeHTMLTags(item.content) }}</div>
          </div>

          <!-- 右侧分页 -->
          <div class="pagination">
            <el-pagination v-if="totalPagesRight > 1" :current-page="currentPageRight" :page-size="10"
              :total="totalItemsRight" @current-change="handleRightPageChange" layout="prev, pager, next">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

    <!-- 弹窗 -->
    <el-dialog v-model="dialogVisible" width="50%">
      <div class="dialog-content">
        <!-- 标题和关闭按钮部分 -->
        <div class="dialog-header">
          <h3 class="dialog-title">{{ selectedItem.title }}</h3>
          <i class="el-dialog__close el-icon el-icon-close" @click="dialogVisible = false"></i>
        </div>

        <!-- 内容区域 -->
        <p>生成于 {{ selectedItem.create_time }}</p>
        <p><strong>标题:</strong> {{ selectedItem.title }}</p>
        <p><strong>文案内容:</strong></p>
        <div class="dialog-content-scroll">
          <div v-html="selectedItem.content"></div>
        </div>
        <p><strong>话题:</strong> {{ selectedItem.topic }}</p>

        <!-- 底部固定按钮 -->
        <div class="dialog-footer">
          <el-button style="background: linear-gradient(135deg, #6a11cb, #2575fc);"
            @click="copyContent">一键复制</el-button>
        </div>
      </div>
    </el-dialog>

  </div>
</template>


<script>
import { ElDialog, ElPagination } from 'element-plus';
import axios from 'axios';
import config from "@/config.js";  // 引入config配置

export default {
  components: {
    ElDialog,
    ElPagination,
  },
  data() {
    return {
      dialogVisible: false, // 控制弹窗是否显示
      selectedItem: {},     // 存储选中的历史记录
      users: [],            // 存储用户数据
      userz: [],            // 存储用户数据
      historyItems: [],     // 存储历史记录数据
      totalItemsLeft: 0,     // 左侧分页总记录数
      totalPagesLeft: 0,     // 左侧分页总页数
      currentPageLeft: 1,    // 当前左侧页码
      totalItemsRight: 0,    // 右侧分页总记录数
      totalPagesRight: 0,    // 右侧分页总页数
      currentPageRight: 1,   // 当前右侧页码
      hoverIndex: null,      // 用于标记当前悬停的历史项
      searchQuery: '',       // 搜索框内容
      titleSearchQuery: '',  // 文案标题搜索框内容


    };
  },
  mounted() {
    this.checkLoginStatus(); // 页面加载时检查登录状态
    this.fetchUserData();   // 初始化获取用户数据
    this.fetchHistoryData(); // 初始化获取历史数据
  },
  methods: {

    // 检查用户是否登录
    checkLoginStatus() {
      const userId = this.$store.state.userId;
      if (!userId) {
        this.goToLogin();  // 如果没有 userId，跳转到登录页面
      }
    },

    // 跳转到登录页面
    goToLogin() {
      this.$router.push({ name: 'LoginPage' });
    },

    fetchFilteredData() {
      const userId = this.$store.state.userId; // 获取用户ID

      if (this.searchQuery.trim() === "") {
        // 如果搜索框为空，调用 fetchUserData 获取全部数据
        this.fetchUserData();
      } else {
        // 否则，按昵称筛选数据
        axios.post(`${config.BASE_URL}/pc/api/search_task_by_nickname.php`, {
          user_id: userId,
          nickname: this.searchQuery,  // 传递搜索的昵称
          page: this.currentPageLeft
        })
          .then(response => {
            const data = response.data;
            this.users = data.tasks;

            // 解析 fx_content 字符串
            this.users.forEach(user => {
              if (user.fx_content) {
                try {
                  // 解析 fx_content 字符串为对象
                  const fxContent = JSON.parse(user.fx_content);
                  user.user_data = fxContent.user_data;  // 将 user_data 添加到 user 对象中
                } catch (error) {
                  console.error('解析 fx_content 失败:', error);
                }
              }
            });
            this.totalItemsLeft = data.total;
            this.totalPagesLeft = data.total_pages;
          })
          .catch(error => {
            console.error('搜索任务失败:', error);
          });
      }
    },

    copyContent() {
      // 创建一个临时文本区域用于复制
      const copyText = `标题:\n${this.selectedItem.title}\n\n文案内容:\n${this.selectedItem.content}\n\n话题:\n${this.selectedItem.topic}`;

      const textarea = document.createElement("textarea");
      textarea.value = copyText;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);

      // 可选：给出复制成功的提示
      this.$message.success('已复制到剪贴板');
    },

    // 获取用户数据
    fetchUserData() {
      const userId = this.$store.state.userId; // 从 Vuex 获取用户ID
      const page = this.currentPageLeft;  // 当前页码

      axios.post(`${config.BASE_URL}/pc/api/get_fx_task.php`, { user_id: userId, page })
        .then(response => {
          const data = response.data;
          console.log('Received data:', data); // 打印查看数据

          this.users = data.tasks; // 设置用户数据

          // 解析 fx_content 字符串
          this.users.forEach(user => {
            if (user.fx_content) {
              try {
                // 解析 fx_content 字符串为对象
                const fxContent = JSON.parse(user.fx_content);
                user.user_data = fxContent.user_data;  // 将 user_data 添加到 user 对象中
              } catch (error) {
                console.error('解析 fx_content 失败:', error);
              }
            }
          });

          this.totalItemsLeft = data.total;  // 总记录数
          this.totalPagesLeft = data.total_pages;  // 总页数

          // 如果分页数据有误，重新调整
          if (this.currentPageLeft > this.totalPagesLeft) {
            this.currentPageLeft = this.totalPagesLeft;
            this.fetchUserData();
          }
        })
        .catch(error => {
          console.error('获取用户数据失败:', error);
        });
    },

    // 点击用户卡片时的处理函数
    handleUserClick(user) {
      // 如果状态是 "已完成"，则跳转
      if (user.status === '已完成') {
        const url = `/pc/user?task_id=${user.task_id}&id=${user.id}`;


        window.location.href = url;  // 跳转到目标链接
      }
    },

    // 省略 task_id 超过10个字符的部分
    truncateTaskId(taskId) {
      if (taskId.length > 10) {
        return taskId.slice(0, 10) + '...';  // 截取前10个字符并加上省略号
      }
      return taskId;
    },

    fetchFilteredHistoryData() {
      const userId = this.$store.state.userId; // 获取用户ID

      if (this.titleSearchQuery.trim() === "") {
        // 如果搜索框为空，调用 fetchHistoryData 获取全部数据
        this.fetchHistoryData();
      } else {
        // 否则，按标题筛选文案历史记录
        axios.post(`${config.BASE_URL}/pc/api/search_task_by_title.php`, {
          user_id: userId,
          title: this.titleSearchQuery,  // 传递搜索的标题
          page: this.currentPageRight
        })
          .then(response => {
            const data = response.data;
            this.historyItems = data.data;
            this.totalItemsRight = data.total;
            this.totalPagesRight = data.pages;
          })
          .catch(error => {
            console.error('搜索文案历史记录失败:', error);
          });
      }
    },
    // 获取文案历史记录数据
    fetchHistoryData() {
      const userId = this.$store.state.userId; // 从 Vuex 获取用户ID
      const page = this.currentPageRight;  // 当前页码

      axios.post(`${config.BASE_URL}/pc/api/get_wenan_history.php`, { user_id: userId, page })
        .then(response => {
          const data = response.data;
          this.historyItems = data.data; // 设置历史记录数据
          this.totalItemsRight = data.total;  // 总记录数
          this.totalPagesRight = data.pages;  // 总页数
        })
        .catch(error => {
          console.error('获取文案历史记录失败:', error);
        });
    },

    // 左侧分页按钮点击时触发
    handleLeftPageChange(page) {
      this.currentPageLeft = page;  // 更新左侧当前页码
      this.fetchUserData();  // 重新加载用户数据
    },

    // 右侧分页按钮点击时触发
    handleRightPageChange(page) {
      this.currentPageRight = page;  // 更新右侧当前页码
      this.fetchHistoryData();  // 重新加载历史记录数据
    },

    // 鼠标悬停时触发的函数，用来放大卡片
    hoverCard(isHovered, index) {
      const card = document.querySelectorAll('.user-card')[index];
      if (isHovered) {
        card.style.transform = 'scale(1.05)';
        card.style.transition = 'transform 0.3s ease-in-out';
      } else {
        card.style.transform = 'scale(1)';
      }
    },

    // 移除 HTML 标签
    removeHTMLTags(content) {
      // 使用正则表达式移除所有 HTML 标签
      return content.replace(/<[^>]+>/g, '');
    },

    // 鼠标悬停时触发的函数，用来放大历史记录卡片
    hoverHistory(isHovered, index) {
      this.hoverIndex = isHovered ? index : null;
    },

    // 点击历史记录卡片，打开弹窗并显示内容
    showContent(item) {
      this.selectedItem = item;
      this.dialogVisible = true;
    }
  }
};
</script>

<style scoped>
.history-page {
  font-family: Arial, sans-serif;
}

.container {
  display: flex;
  justify-content: center;
}

.search-container {
  margin-top: 10px;
}

.search-input {
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  gap: 30px;
}

.left-panel {
  flex: 0 0 380px;
  padding: 20px;
  border-radius: 8px;
}

.right-panel {
  flex: 0 0 700px;
  padding: 20px;
  border-radius: 8px;
}

h2 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: left;
}

.user-card {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.user-info {
  display: flex;
  text-align: left;
  flex-direction: column;
}

.username {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.last-visit {
  font-size: 12px;
  color: #777;
}

.history-item {
  margin-bottom: 20px;
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.history-item.hovered {
  transform: scale(1.05);
}

.item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #555;
  gap: 10px;
}

.item-tag {
  background-color: rgb(234 179 8 / var(--tw-bg-opacity, 1));
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
}

.item-time {
  color: #999;
  text-align: center;
  flex-grow: 1;
}

.item-username {
  color: #333;
  text-align: center;
  flex-shrink: 0;
}

.item-content {
  margin-top: 10px;
  font-size: 14px;
  text-align: left;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.el-dialog {
  padding: 20px;
}

.el-dialog__body {
  font-size: 14px;
  color: #333;
  line-height: 1.6;
}

.dialog-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.dialog-title {
  font-weight: bold;
  font-size: 22px;
  margin: 0;
  text-align: left;
}

.el-dialog__close {
  cursor: pointer;
  font-size: 24px;
  color: #999;
}

.dialog-content-scroll {
  max-height: 300px;
  /* 设置最大高度 */
  overflow-y: auto;
  /* 允许内容垂直滚动 */
  margin-bottom: 20px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  text-align: left;
}

.dialog-footer .el-button {
  background-color: #409eff;
  color: white;
  border-color: #409eff;
}
</style>
