import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/HomePage.vue'
import LoginPage from '../views/LoginPage.vue'
import ProfilePage from '../views/ProfilePage.vue'
import HistoryPage from '@/views/HistoryPage.vue';  // 导入新的历史记录页面
import SupperPage from '@/views/SupperPage.vue';  // 导入新的用户页面
import UserPage from '@/views/UserPage.vue';  // 导入新的用户页面





const routes = [
  { path: '/', redirect: '/home' },
  { path: '/home', name: 'HomePage', component: HomePage },  // 确保 name 为 'HomePage'
  { path: '/profile', name: 'ProfilePage', component: ProfilePage },  // 确保 name 为 'ProfilePage'
  { path: '/history', name: 'HistoryPage', component: HistoryPage },  // 确保 name 为 'HistoryPage'
  { path: '/super', name: 'SupperPage', component: SupperPage },  // 确保 name 为 'SupperPage'

  { path: '/user', name: 'UserPage', component: UserPage, props: (route) => ({ taskId: route.query.task_id, Id: route.query.id  }) },  // 添加新的用户页面路由
  { path: '/login', name: 'LoginPage', component: LoginPage }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
