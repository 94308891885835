<template>
  <div class="home">
    <!-- 第一板块：AI 分析抖音用户 -->
    <div class="analysis-block">
      <h2 class="flow-text">AI 分析抖音用户</h2>

      <div class="analysis-input">
        <!-- 1. 输入框 -->
        <el-input v-model="douyinLink" placeholder="例如：https://www.douyin.com/user/MS4wLjABAAA..." class="link-input"
          size="large" />
        <!-- 2. 下拉框 -->
        <el-select v-model="selectedMode" class="mode-select" placeholder="选择模式" size="large">
          <el-option label="极速模式" value="10" />
          <el-option label="完全模式" value="360" />
        </el-select>

        <p class="analysis-hint">
          请输入<span style="color: brown; font-weight: bold;">抖音用户主页链接，</span>AI 将会自动分析该用户最受欢迎的视频内容，提供用户IP分析报告及文案的复刻服务。
        </p>
      </div>

      <div class="analysis-action">
        <!-- 按钮文字和禁用状态 -->
        <el-button type="primary" class="analysis-button" :disabled="!douyinLink || !selectedMode || !isLoggedIn"
          @click="startAnalysis">
          {{ isLoggedIn ? '⚡ 开始分析' : '请先登录' }}
        </el-button>
      </div>
    </div>

    <!-- 第二板块：使用说明、参与内测、积分消耗标准 -->
    <div class="info-container">
      <!-- 左侧：使用说明 + 积分消耗标准 -->
      <div class="left-block">
        <h3>使用说明</h3>
        <ol>
          <li>访问抖音用户的主页</li>
          <li>复制浏览器地址栏中的链接</li>
          <li>粘贴到上方输入框</li>
          <li style="color: brown; font-weight: bold;">
            极速模式【分析部分视频，1分钟左右】完全模式【能分析拥有1千左右视频的抖音用户，时间较长，5分钟左右】
          </li>
          <li>点击“开始分析”</li>
        </ol>

        <h3>积分消耗标准</h3>
        <ul>
          <!-- 动态展示从后端获取的配置 -->
          <li>查询成本：{{ cxNum }}点/1次</li>
          <li>分析报告：{{ fxNum }}点/1次</li>
          <li>解析视频：{{ jxNum }}点/1次</li>
          <li>获取文案：{{ yyNum }}点/1次</li>
          <li>复刻成本：{{ fkNum }}点/1次</li>
        </ul>
      </div>

      <!-- 右侧：参与内测 -->
      <div class="right-block">
        <!-- 使用后端返回的 name + qrcode_url -->
        <h3>扫码联系</h3>
        <p>
          {{ contactName || '小蚂蚁AI目前处于内测阶段，如有兴趣参与内测，请使用微信扫描以下二维码：' }}
        </p>
        <div class="qrcode-container">
          <!-- 如果 contactQrcodeUrl 为空, 可以展示一个默认二维码或者留空 -->
          <img :src="contactQrcodeUrl || defaultQrcode" alt="二维码" class="qrcode" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import config from "@/config.js";

export default {
  name: "HomePage",
  data() {
    return {
      douyinLink: "",    // 输入框中的链接
      selectedMode: "",  // 下拉框选择的模式：极速(10)或完全(360)
      contactName: "",   // 从后端接口中拿到的 name
      contactQrcodeUrl: "", // 从后端接口中拿到的 qrcode_url
      defaultQrcode: "", // 如需默认二维码，可自行换图
      // 下面是获取到的成本配置
      cxNum: "...",
      fxNum: "...",
      jxNum: "...",
      yyNum: "...",
      fkNum: "...",
      isLoggedIn: false, // 判断用户是否登录
    };
  },
  mounted() {
    this.fetchContactInfo();
    this.fetchCostConfig();
    this.checkLoginStatus(); // 检查登录状态

  },
  methods: {
    // 获取当前域名
    getCurrentHost() {
      return window.location.origin;
    },
    // 调用接口: get_contact_info.php
    fetchContactInfo() {
      const url = `${config.BASE_URL}/pc/api/get_contact_info.php`;
      axios.post(url, {
        agent_host: this.getCurrentHost(),
      })
        .then(res => {
          if (res.data.error) {
            console.warn("获取联系我们信息失败：", res.data.error);
          } else {
            this.contactName = res.data.name || "小蚂蚁AI";
            this.contactQrcodeUrl = res.data.qrcode_url || "";
          }
        })
        .catch(err => {
          console.error("请求get_contact_info接口错误：", err);
        });
    },

    // 获取积分消耗配置
    fetchCostConfig() {
      const url = `${config.BASE_URL}/pc/api/get_cost_config.php`; // 你定义的接口地址
      axios.get(url)
        .then(res => {
          if (res.data.success) {
            // { success: true, data: { cx_num: "10", fx_num: "15", ... } }
            const d = res.data.data;
            this.cxNum = d.cx_num || "10";
            this.fxNum = d.fx_num || "15";
            this.jxNum = d.jx_num || "8";
            this.yyNum = d.yy_num || "2";
            this.fkNum = d.fk_num || "1";
          } else {
            console.warn("获取成本配置失败：", res.data.error);
          }
        })
        .catch(err => {
          console.error("请求 get_cost_config.php 接口错误：", err);
        });
    },

    // 检查用户是否登录
    checkLoginStatus() {
      const userId = this.$store.state.userId;
      if (userId) {
        this.isLoggedIn = true;
      } else {
        this.isLoggedIn = false;
      }
    },
    // 启动分析任务
    startAnalysis() {
      if (!this.douyinLink.includes("douyin.com")) {
        this.$message.warning("不是抖音的链接，请勿填写，谢谢！");
        return;
      }
      // 判断是否为抖音视频链接
      if (this.douyinLink.includes("douyin.com/video")) {
        this.$message.warning("您这是视频链接，不是主页链接，请检查一下，谢谢！");
        return;
      }

      // 获取当前登录的用户ID
      const userId = this.$store.state.userId; // Vuex 中存储的用户ID

      // 发送分析任务创建请求
      const url = `${config.BASE_URL}/pc/api/create_fx_task.php`;
      axios.post(url, {
        user_id: userId,
        douyinLink: this.douyinLink,
        selectedMode: this.selectedMode
      })
        .then(response => {
          if (response.data.success) {
            this.$message.success("任务创建成功，请稍后于历史记录中查询！");
          } else {
            this.$message.error("任务创建失败：" + response.data.error);
          }
        })
        .catch(error => {
          console.error("请求 create_fx_task.php 接口错误：", error);
          this.$message.error("请求失败，请稍后再试");
        });
    }
  }
};
</script>

<style scoped>
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 10px;
}

/* 第一板块：AI 分析抖音用户 */
.analysis-block {
  width: 100%;
  max-width: 880px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  padding: 30px;
  margin-bottom: 10px;
  text-align: center;
}

/* 流光效果标题 */
.flow-text {
  margin-bottom: 30px;
  font-size: 26px;
  font-weight: 700;
  background: linear-gradient(90deg, #00b3ff, #a100ff, #00b3ff);
  background-size: 200% 100%;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
  animation: flow 3s linear infinite;
}

@keyframes flow {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

/* 输入框 + 下拉框在同一行，宽度与按钮一致 */
.analysis-input {
  margin-bottom: 20px;
  text-align: left;
  max-width: 850px;
  margin: 0 auto;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

/* 输入框 (el-input) */
.link-input {
  flex: 1;
  min-height: 40px;
  font-size: 18px;
  border-radius: 15px;
}

/* 下拉框 (el-select) */
.mode-select {
  width: 150px;
  min-height: 40px;
  font-size: 18px;
  border-radius: 15px;
}

.mode-select ::v-deep .el-input__inner {
  height: 50px !important;
  line-height: 50px !important;
  border-radius: 15px !important;
  font-size: 18px !important;
}

.analysis-hint {
  margin-top: 10px;
  color: #666;
  font-size: 16px;
  line-height: 1.4;
  width: 100%;
}

.analysis-action {
  margin-top: 10px;
}

.analysis-button {
  font-size: 20px;
  height: 45px;
  width: 100%;
  max-width: 850px;
  padding: 12px 30px;
  border-radius: 8px;
  color: #fff;
  border: none;
  background: linear-gradient(90deg, #00b3ff, #a100ff, #00b3ff);
  background-size: 200% 100%;
  animation: flowbtn 3s linear infinite;
}

@keyframes flowbtn {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

/* 第二板块：使用说明/参与内测/积分消耗标准容器 */
.info-container {
  display: flex;
  width: 100%;
  max-width: 930px;
  gap: 20px;
  justify-content: center;
}

.left-block,
.right-block {
  flex: 1;
  max-width: 460px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  padding: 30px;
}

.left-block {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.left-block h3 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 20px;
}

.left-block ol,
.left-block ul {
  margin-left: 20px;
  line-height: 1.6;
  font-size: 16px;
}

.right-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.right-block h3 {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 20px;
}

.right-block p {
  font-size: 16px;
  line-height: 1.6;
  text-align: left;
}

/* 二维码示例 */
.qrcode-container {
  margin-top: 20px;
  text-align: center;
}

.qrcode {
  width: 140px;
  height: 140px;
  border: 1px solid #eee;
  object-fit: contain;
  border-radius: 6px;
}
</style>
