<template>
    <div class="profile-page">
        <!-- 顶部工具栏 -->
        <div class="toolbar">
            <!-- 搜索框 -->
            <div class="search-container">
                <el-input v-model="searchQuery" placeholder="请输入搜索内容" clearable @clear="handleSearch"
                    @keyup.enter="handleSearch">
                    <template #prefix>
                        <el-icon class="search-icon">
                            <img src="https://jzguai01.oss-cn-shanghai.aliyuncs.com/uploads/images/20250223/20250223180842fb0f76828.png"
                                alt="搜索" />
                        </el-icon>

                    </template>
                </el-input>
            </div>

            <!-- 分页按钮 -->
            <div class="pagination-container">
                <el-pagination background layout="prev, pager, next" :total="pagination.total"
                    :page-size="pagination.pageSize" :current-page="pagination.page"
                    @current-change="handlePageChange" />
            </div>
        </div>

        <!-- 数据展示区域 -->
        <div class="row">
            <div class="card" v-for="(item, index) in displayData" :key="index" @click="handleCardClick(item)">
                <div class="card-content">
                    <div class="text-content">
                        <h3 class="title">{{ formatText(item.bot_name, 10) }}</h3>
                        <p class="description">{{ formatText(item.description, 15) }}</p>
                    </div>
                    <div class="image-container">
                        <img :src="item.icon_url" alt="Icon" />
                    </div>
                </div>
                <div class="label" :class="{ 'free': item.dianshu <= 0, 'paid': item.dianshu > 0 }">
                    {{ item.dianshu <= 0 ? '免费' : `${item.dianshu} 积分` }} </div>
                        <div class="footer">
                            <span>最新更新时间：{{ formatDate(item.publish_time) }}</span>
                        </div>
                </div>
            </div>

            <!-- 聊天框弹窗 -->
            <div class="super-assistant-dialog" v-if="activeBot">
                <div class="assistant-container">
                    <!-- 右上角关闭按钮 -->
                    <!-- 右上角关闭按钮 -->
                    <div class="close-button" @click="closeChatDialog">
                        <img src="https://jzguai01.oss-cn-shanghai.aliyuncs.com/uploads/images/20250223/20250223154157add0c2215.png"
                            alt="关闭" />
                    </div>


                    <!-- 左侧：会话管理 -->
                    <div class="assistant-sidebar">
                        <div class="sidebar-header" style="width: 210px;margin-bottom: 20px;">
                            <!-- 新建会话按钮 -->
                            <el-button type="primary" style="background: linear-gradient(135deg, #6a11cb, #2575fc);"
                                size="large" @click="handleNewConversation" :disabled="isAIResponding">
                                新建会话
                            </el-button>

                            <!-- 清空会话按钮 -->
                            <el-button size="large" @click="confirmClearConversations" :disabled="isAIResponding">
                                清空会话
                            </el-button>

                        </div>
                        <div class="conversation-list">
                            <div v-for="conv in conversationHistory" :key="conv.id" class="conversation-item"
                                :class="{ active: activeConvId === conv.id }" @click="setActiveConversation(conv)">
                                <p class="conversation-title" :title="conv.title">{{ formatTitle(conv.title) }}</p>
                                <img class="delete-icon"
                                    src="https://jzguai01.oss-cn-shanghai.aliyuncs.com/uploads/images/20250122/20250122233241207982156.png"
                                    alt="删除" @click.stop="confirmDeleteConversation(conv.id)" />
                            </div>
                        </div>
                    </div>

                    <!-- 右侧：聊天框 -->
                    <div class="assistant-main">
                        <div class="chat-header">
                            <h2>{{ activeConvTitle }}</h2>
                        </div>

                        <div class="chat-content" ref="chatContentRef">
                            <div v-for="msg in messages" :key="msg.id" :class="['chat-message', msg.role]">
                                <div class="message-bubble">
                                    <p v-if="msg.isThinking" class="thinking-text">{{ msg.text }}</p>
                                    <p v-else-if="msg.isTyping" class="typing-text">{{ msg.text }}<span
                                            class="typing-cursor">|</span></p>
                                    <p v-else v-html="msg.text"></p>

                                    <!-- 只有AI回复时且非思考中时才显示复制按钮 -->
                                    <div v-if="msg.role === 'assistant' && !msg.isThinking && typeof msg.text === 'string' && !msg.text.includes('<')"
                                        class="copy-button-container">
                                        <button class="copy-button" @click="copyToClipboard(msg.text)">
                                            复制
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>




                        <div class="chat-input-container">
                            <div class="input-wrapper">

                                <!-- 上传按钮 -->
                                <el-button class="upload-button" @click="handleUploadFile">
                                    上传文件
                                </el-button>

                                <el-input v-model="inputText" placeholder="请输入内容" class="input-box" type="input"
                                    :disabled="isAIResponding" @keydown.enter.prevent="handleSend" />
                                <el-button class="send-button"
                                    style="background: linear-gradient(135deg, #6a11cb, #2575fc);" type="primary"
                                    :disabled="isAIResponding" @click="handleSend">
                                    发送
                                </el-button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 图片预览弹窗 -->
                <el-dialog v-model="isPreviewVisible" title="图片预览" width="80%" :close-on-click-modal="false">
                    <img :src="previewImageUrl" alt="预览图" style="width: 100%; height: auto;" />
                    <template #footer>
                        <el-button @click="closePreview">关闭</el-button>
                    </template>
                </el-dialog>

                <!-- 二次确认弹窗 -->
                <el-dialog v-model="confirmDialogVisible" title="确认操作" width="300px" :close-on-click-modal="false">
                    <p>{{ confirmMessage }}</p>
                    <template #footer>
                        <el-button @click="confirmDialogVisible = false">取消</el-button>
                        <el-button style="background: linear-gradient(135deg, #6a11cb, #2575fc); border: none;"
                            type="primary" @click="handleConfirm">确定</el-button>
                    </template>
                </el-dialog>
            </div>

        </div>
</template>

<script>
import { ElMessage } from "element-plus";
import axios from "axios";
import config from "@/config.js"; // 引入配置文件
import { marked } from "marked"; // 引入 marked 库
import { beforeUpload, uploadToOSS, getUploadSignature } from '@/utils/uploadUtils';

export default {
    name: "SupperPage",
    data() {
        return {
            displayData: [], // 当前页数据
            searchQuery: '', // 搜索关键词
            pagination: {
                page: 1, // 当前页码
                pageSize: 15, // 每页数据量
                total: 0, // 总数据量
            },
            activeBot: null, // 当前选中的机器人
            conversationHistory: [], // 会话历史
            activeConvId: null, // 当前会话ID
            activeConvTitle: "", // 当前会话标题
            messages: [], // 当前会话的消息列表
            inputText: "", // 输入框内容
            confirmDialogVisible: false, // 确认弹窗是否显示
            confirmMessage: "", // 确认弹窗的提示信息
            confirmAction: null, // 确认弹窗的回调函数
            isAIResponding: false, // 标记AI是否正在回复
            previewImageUrl: "", // 当前预览图片的 URL
            isPreviewVisible: false, // 是否显示预览弹窗
        };
    },
    mounted() {
        this.checkLoginStatus(); // 页面加载时检查登录状态
        this.fetchData(); // 页面加载时调用接口获取数据
    },


    methods: {
        // 获取用户ID
        getUserId() {
            const userId = this.$store.state.userId; // 从 Vuex 获取用户 ID
            if (!userId) {
                ElMessage.error("用户未登录或用户ID无效");
                return null;
            }
            return userId;
        },
        // 复制到剪贴板
        copyToClipboard(text) {
            if (typeof text !== 'string') {
                text = String(text); // 将非字符串转换为字符串
            }

            if (navigator.clipboard) {
                navigator.clipboard.writeText(text).then(() => {
                    ElMessage.success("内容已复制");
                }).catch(err => {
                    console.error("复制失败", err);
                    ElMessage.error("复制失败，请稍后重试");
                });
            } else {
                const textarea = document.createElement("textarea");
                textarea.value = text;
                document.body.appendChild(textarea);
                textarea.select();
                document.execCommand("copy");
                document.body.removeChild(textarea);
                ElMessage.success("内容已复制");
            }
        },


        // 点击图片时显示预览
        handleImagePreview(url) {
            this.previewImageUrl = url; // 设置预览图片的 URL
            this.isPreviewVisible = true; // 显示预览弹窗
        },

        // 关闭预览弹窗
        closePreview() {
            this.isPreviewVisible = false;
            this.previewImageUrl = "";
        },
        // 检查用户是否登录
        checkLoginStatus() {
            const userId = this.$store.state.userId;
            if (!userId) {
                this.goToLogin();  // 如果没有 userId，跳转到登录页面
            }
        },

        // 上传文件方法
        async handleUploadFile() {
            try {
                // 打开文件选择器
                const fileInput = document.createElement('input');
                fileInput.type = 'file'; // 允许上传任意文件
                fileInput.onchange = async (event) => {
                    const file = event.target.files[0];
                    if (!file) return;

                    // 校验文件
                    const isValid = await beforeUpload(file, {
                        maxSize: 50, // 文件最大大小 50MB
                        allowedTypes: [], // 允许的文件类型为空，支持所有类型
                    });
                    if (!isValid) return;

                    // 获取上传签名
                    const signatureData = await getUploadSignature('file');
                    if (!signatureData) {
                        ElMessage.error("获取上传签名失败");
                        return;
                    }

                    // 上传文件到 OSS
                    const uploadResult = await uploadToOSS(file, signatureData);
                    if (uploadResult.success) {
                        ElMessage.success("文件上传成功");
                        this.inputText = uploadResult.url; // 更新输入框内容为上传后的文件URL
                       
                    } else {
                        ElMessage.error("文件上传失败");
                    }
                };
                fileInput.click(); // 触发文件选择
            } catch (error) {
                console.error("上传文件失败:", error);
                ElMessage.error("上传文件失败，请重试");
            }
        },
        // 跳转到登录页面
        goToLogin() {
            this.$router.push({ name: 'LoginPage' });
        },
        async setActiveConversation(conv) {
            if (this.isAIResponding) {
                ElMessage.warning("AI 正在回复，请稍后再试");
                return; // 禁止切换会话
            }

            this.activeConvId = conv.id;
            this.activeConvTitle = conv.title;

            // 每次切换会话时，确保清空消息并加载新的消息内容
            this.messages = []; // 清空当前消息
            // 添加日志以检查是否触发
            console.log(`切换到会话: ${conv.title}`);
            await this.fetchMessages(); // 获取该会话的最新消息

        },


        // 格式化会话标题，最多显示 6 个字
        formatTitle(title) {
            if (!title) return "";
            return title.length > 6 ? title.substring(0, 13) + "..." : title;
        },
        // 确认删除操作
        async handleConfirm() {
            if (this.confirmAction) {
                // 执行删除操作
                await this.confirmAction();  // 调用 confirmAction 回调函数
                this.confirmDialogVisible = false;  // 关闭弹窗
            }
        },
        // 关闭聊天弹窗
        closeChatDialog() {
            this.activeBot = null; // 设置 activeBot 为 null，隐藏聊天框
            this.messages = []; // 清空消息列表（如果需要）
            this.activeConvId = null; // 重置当前会话ID（如果需要）
            this.activeConvTitle = ""; // 重置会话标题（如果需要）
        },
        // 获取数据
        async fetchData() {
            try {
                let response;
                if (this.searchQuery.trim()) {
                    response = await axios.get(`${config.BASE_URL}/pc/api/search_coze_agents.php`, {
                        params: { bot_name: this.searchQuery },
                    });
                } else {
                    response = await axios.get(`${config.BASE_URL}/pc/api/get_coze_agents.php`, {
                        params: { page: this.pagination.page, pageSize: this.pagination.pageSize },
                    });
                }

                if (response.data.success) {
                    this.displayData = response.data.data;
                    this.pagination.total = parseInt(response.data.pagination?.total || response.data.data.length);
                }
            } catch (error) {
                console.error("数据获取失败", error);
            }
        },

        // 处理搜索
        async handleSearch() {
            if (!this.searchQuery.trim()) {
                this.$message.warning("请输入搜索内容");
                this.fetchData();
                return;
            }

            try {
                const response = await axios.get(`${config.BASE_URL}/pc/api/search_coze_agents.php`, {
                    params: { bot_name: this.searchQuery },
                });
                if (response.data.success) {
                    this.displayData = response.data.data;
                    this.pagination.total = response.data.data.length;
                    this.pagination.page = 1;
                }
            } catch (error) {
                console.error("搜索失败", error);
                this.$message.error("搜索失败，请稍后重试");
            }
        },

        // 处理分页切换
        handlePageChange(page) {
            this.pagination.page = page;
            this.fetchData();
        },

        // 格式化文本
        formatText(text, maxLength) {
            if (!text) return "";
            return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
        },

        // 格式化时间戳为日期
        formatDate(timestamp) {
            if (!timestamp) return "";
            const date = new Date(timestamp * 1000);
            const options = {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
            };
            return date.toLocaleString("zh-CN", options);
        },

        // 点击卡片，弹出对应的聊天框
        handleCardClick(item) {
            this.activeBot = item; // 设定当前选中的机器人
            this.fetchConversations(); // 获取会话历史
        },

        // 获取会话历史
        async fetchConversations() {
            const userId = this.getUserId();
            if (!userId || !this.activeBot) return;

            try {
                const response = await axios.post(`${config.BASE_URL}/pc/api/super_assistant_api.php`, {
                    action: "get",
                    user_id: userId,
                    bot_id: this.activeBot.bot_id, // 修正 bot_id
                });

                if (response.data.success) {
                    this.conversationHistory = response.data.data;
                    console.log("会话历史", this.conversationHistory); // 检查会话历史数据

                    if (this.conversationHistory.length === 0) {
                        // 如果没有会话，自动创建一个新的会话
                        this.handleNewConversation();
                    } else {
                        this.activeConvId = this.conversationHistory[0].id;
                        this.activeConvTitle = this.conversationHistory[0].title;
                        this.fetchMessages();
                    }
                } else {
                    ElMessage.error("获取会话历史失败");
                }
            } catch (error) {
                console.error("获取会话历史失败:", error);
                ElMessage.error("获取会话历史失败，请检查网络");
            }
        },


        async fetchMessages() {
            const userId = this.getUserId();
            if (!userId || !this.activeBot || !this.activeConvId) return;

            try {
                // 调用新API接口获取指定会话的内容
                const response = await axios.post(`${config.BASE_URL}/pc/api/super_assistant_api.php`, {
                    action: "getById",
                    user_id: userId,
                    bot_id: this.activeBot.bot_id, // 传递 bot_id
                    id: this.activeConvId, // 传递会话ID
                    title: this.activeConvTitle // 传递标题
                });

                if (response.data.success) {
                    // 成功获取消息内容
                    this.messages = JSON.parse(response.data.data[0].content); // 确保从数据库中获取到正确的会话内容
                } else {
                    this.messages = [];
                    ElMessage.error("没有找到会话内容");
                }
            } catch (error) {
                console.error("获取会话内容失败:", error);
                this.messages = [];
                ElMessage.error("获取会话内容失败，请检查网络");
            }
        },


        /* eslint-disable no-useless-escape */
        isMarkdown(content) {
            // 检查是否包含 Markdown 特有的符号：
            // 1. * 或 - 用于列表项
            // 2. # 用于标题
            // 3. ![]() 用于图片
            // 4. []() 用于链接
            return /(\*|-|#|!\[[^\]]+\]\([^\)]+\)|\[[^\]]+\]\([^\)]+\))/i.test(content);  // 包含链接的检查
        },
        /* eslint-enable no-useless-escape */





        // 转换 Markdown 为 HTML，包括图片和视频
        convertMarkdownToHtml(content) {
            const renderer = new marked.Renderer();

            // 图片处理
            renderer.image = function (href, title, text) {
                let url = href;  // 默认情况下，假设 href 已经是一个字符串 URL

                if (typeof href === 'object' && href.href) {
                    // 如果 href 是对象，并且包含 href 字段，取出 href 字段中的 URL
                    url = href.href;
                }

                const decodedUrl = url;  // 解码 URL

                // 返回图片元素，并附加点击事件来预览
                return `
        <img 
            src="${decodedUrl}" 
            alt="${text}" 
            class="chat-image"
            @click="handleImagePreview('${decodedUrl}')" 
            style="max-width: 100%; max-height: 200px; object-fit: contain; cursor: pointer;" />
    `;
            };

            // 链接处理
            renderer.link = function (href, title, text) {
                let url = href;  // 默认情况下，假设 href 已经是一个字符串 URL

                if (typeof href === 'object' && href.href) {
                    // 如果 href 是对象，并且包含 href 字段，取出 href 字段中的 URL
                    url = href.href;
                }

                const decodedUrl = url;  // 解码 URL

                // 判断链接是否为图片文件链接（支持常见的图片格式）
                const imageRegex = /\.(jpg|jpeg|png|gif|bmp|webp|tiff|svg)$/i;
                if (imageRegex.test(decodedUrl)) {
                    return `<a href="${decodedUrl}" target="_blank" class="chat-image-link">点击查看图片</a>`;
                }

                // 判断链接是否为视频文件链接（支持常见的视频格式）
                const videoRegex = /\.(mp4|avi|mov|mkv|flv|wmv|webm|mpeg|mpg)$/i;
                if (videoRegex.test(decodedUrl)) {
                    return `<a href="${decodedUrl}" target="_blank" class="chat-video-link">点击观看视频</a>`;
                }

                // 判断链接是否为音频文件链接（支持常见的音频格式）
                const audioRegex = /\.(mp3|wav|ogg|flac|aac|wma|m4a)$/i;
                if (audioRegex.test(decodedUrl)) {
                    return `<a href="${decodedUrl}" target="_blank" class="chat-audio-link">点击收听音频</a>`;
                }

                // 判断链接是否为文件下载链接（支持常见的文件格式）
                const fileRegex = /\.(pdf|doc|docx|txt|xlsx|ppt|pptx|zip|rar|tar|gz|csv)$/i;
                if (fileRegex.test(decodedUrl)) {
                    return `<a href="${decodedUrl}" target="_blank" class="chat-file-link">点击查看文件</a>`;
                }

                // 普通链接处理
                return `<a href="${decodedUrl}" target="_blank">${text || '点击查看'}</a>`;
            };

            // 转换 Markdown 为 HTML
            return marked(content, { renderer: renderer });
        },






        // 滚动到底部
        scrollToBottom() {
            this.$nextTick(() => {
                const chatContentRef = this.$refs.chatContentRef;
                if (chatContentRef) {
                    chatContentRef.scrollTop = chatContentRef.scrollHeight;
                }
            });
        },

        // 发送消息后更新会话内容
        async handleSend() {
            if (!this.inputText.trim()) {
                ElMessage.warning("请输入内容后再发送");
                return;
            }

            if (this.isAIResponding) {
                ElMessage.warning("AI 正在回复，请稍后再试");
                return; // 禁止发送消息和切换会话
            }

            // 用户发送的消息
            this.messages.push({
                id: Date.now(),
                role: "user",
                text: this.inputText,
            });

            const thinkingMessage = {
                id: Date.now() + 1,
                role: "assistant",
                text: "思考中，请稍等···",
                isThinking: true,
            };
            this.messages.push(thinkingMessage);
            this.scrollToBottom();
            this.isAIResponding = true; // 开始回复时禁止操作

            const userId = this.getUserId();
            try {
                const response = await axios.post(`${config.BASE_URL}/pc/api/coze_api.php`, {
                    message: this.inputText,
                    bot_id: this.activeBot.bot_id,
                    user_id: userId,
                }, {
                    headers: { 'Content-Type': 'application/json' },
                });

                // 移除思考中的消息
                this.messages = this.messages.filter((msg) => !msg.isThinking);
                let aiMessage = response.data.response[0]; // 使用 let 来声明 aiMessage 变量

                // 检查是否为Markdown格式
                if (this.isMarkdown(aiMessage)) {
                    // 将Markdown转换为HTML
                    aiMessage = this.convertMarkdownToHtml(aiMessage);
                }

                // 添加AI回复的消息
                this.messages.push({
                    id: Date.now(),
                    role: "assistant",
                    text: aiMessage,
                });

                this.scrollToBottom();
                this.inputText = "";

                // 如果没有会话历史，自动新建会话
                if (this.conversationHistory.length === 0) {
                    await this.handleNewConversation();
                }

                this.updateConversationContent();
                this.isAIResponding = false; // 回复完成后恢复操作
            } catch (error) {
                console.error("发送消息失败:", error);
                ElMessage.error("发送消息失败，请稍后重试");
                this.messages = this.messages.filter((msg) => !msg.isThinking);
                this.isAIResponding = false; // 回复完成后恢复操作
            }
        },



        // 显示 AI 返回内容
        parseResponse(response) {
            // 如果返回的是 JSON 格式字符串，解析并显示
            try {
                let parsedResponse = JSON.parse(response);
                return parsedResponse.content || '无内容';
            } catch (error) {
                return response;  // 如果无法解析为 JSON，直接返回原始字符串
            }
        },

        // 更新会话内容
        async updateConversationContent() {
            const conv = this.conversationHistory.find((c) => c.id === this.activeConvId);
            if (conv) {
                const userId = this.getUserId();
                if (!userId || !this.activeBot) return;

                if ((!conv.title || conv.title === "新的会话") && this.messages.length > 0) {
                    conv.title = this.messages[0].text.substring(0, 50);
                }

                try {
                    const response = await axios.post(`${config.BASE_URL}/pc/api/super_assistant_api.php`, {
                        action: "update",
                        user_id: userId,
                        bot_id: this.activeBot.bot_id,
                        id: conv.id,
                        title: conv.title,
                        content: JSON.stringify(this.messages), // 保存当前消息
                    });

                    if (!response.data.success) {
                        ElMessage.error("更新会话内容失败");
                    } else {
                        ElMessage.success("会话内容更新成功");
                    }
                } catch (error) {
                    console.error("更新会话内容失败:", error);
                    ElMessage.error("更新会话内容失败，请检查网络");
                }
            }
        },

        async handleNewConversation() {
            if (this.isAIResponding) {
                ElMessage.warning("AI 正在回复，请稍后再试");
                return; // 禁止新建会话
            }

            const userId = this.getUserId();
            if (!userId || !this.activeBot) return;

            try {
                // 使用默认标题 "新的会话" 或者更符合需求的标题
                const title = "新的会话";  // 使用固定的标题或自定义标题

                const response = await axios.post(`${config.BASE_URL}/pc/api/super_assistant_api.php`, {
                    action: "add",
                    user_id: userId,
                    bot_id: this.activeBot.bot_id,
                    title: title,
                    content: JSON.stringify([]), // 创建新会话时内容为空
                });

                if (response.data.success) {
                    // 获取最新的会话列表
                    await this.fetchConversations();

                    // 自动选中最新的会话
                    const newConv = this.conversationHistory[0];
                    this.activeConvId = newConv.id;
                    this.activeConvTitle = newConv.title; // 确保标题更新
                    // 清空消息内容，确保是新的会话
                    this.messages = [];
                    ElMessage.success("已新建一个会话并填充内容");

                    // 确保在创建新会话后直接获取会话消息
                    await this.fetchMessages(); // 重新获取消息，确保内容更新
                } else {
                    ElMessage.error("新建会话失败");
                }
            } catch (error) {
                console.error("新建会话失败:", error);
                ElMessage.error("新建会话失败，请检查网络");
            }
        },


        // 清空会话
        async handleClearConversations() {
            if (this.isAIResponding) {
                ElMessage.warning("AI 正在回复，请稍后再试");
                return; // 禁止清空会话
            }

            const userId = this.getUserId();
            if (!userId || !this.activeBot) return;

            try {
                const response = await axios.post(`${config.BASE_URL}/pc/api/super_assistant_api.php`, {
                    action: "clear",
                    user_id: userId,
                    bot_id: this.activeBot.bot_id,
                });

                if (response.data.success) {
                    this.conversationHistory = [];
                    this.messages = [];
                    ElMessage.success("已清空所有历史会话");

                    // 清空后自动新建一个会话
                    await this.handleNewConversation();
                } else {
                    ElMessage.error("清空会话失败");
                }
            } catch (error) {
                console.error("清空会话失败:", error);
                ElMessage.error("清空会话失败，请检查网络");
            }
        },

        // 显示确认清空会话弹窗
        confirmClearConversations() {
            this.confirmMessage = "确定清空所有会话吗？";
            this.confirmAction = this.handleClearConversations; // 设置清空操作
            this.confirmDialogVisible = true; // 显示弹窗
        },
        // 显示确认删除弹窗
        confirmDeleteConversation(id) {
            this.confirmMessage = "确定删除该会话吗？";
            this.confirmAction = () => this.deleteConversation(id); // 设置删除操作
            this.confirmDialogVisible = true; // 显示弹窗
        },

        // 删除会话
        async deleteConversation(id) {
            if (this.isAIResponding) {
                ElMessage.warning("AI 正在回复，请稍后再试");
                return; // 禁止删除会话
            }

            const userId = this.getUserId();
            if (!userId || !this.activeBot) return;

            try {
                const response = await axios.post(`${config.BASE_URL}/pc/api/super_assistant_api.php`, {
                    action: "delete",
                    user_id: userId,
                    bot_id: this.activeBot.bot_id,
                    id: id,
                });

                if (response.data.success) {
                    this.fetchConversations();
                    ElMessage.success("已删除会话");
                } else {
                    ElMessage.error("删除会话失败");
                }
            } catch (error) {
                console.error("删除会话失败:", error);
                ElMessage.error("删除会话失败，请检查网络");
            }
        },
    },
};
</script>


<style scoped>
.profile-page {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

/* 顶部工具栏 */
.toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* 搜索框容器 */
.search-container {
    flex: 1;
    max-width: 300px;
    margin-left: 10px;
}

/* 调整图标的样式 */
.search-icon img {
    width: 20px;
    /* 图标宽度 */
    height: 20px;
    /* 图标高度 */
    vertical-align: middle;
    /* 垂直居中 */
}

/* 分页按钮容器 */
.pagination-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    margin-right: 30px;
}

/* 数据展示区域 */
.row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: flex-start;
    padding: 10px;
}

.card {
    flex: 0 0 calc(33.333% - 20px);
    max-width: calc(33.333% - 20px);
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 10px 20px 20px 20px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    transition: transform 0.3s ease;
}

/* 鼠标悬停放大效果 */
.card:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* 响应式调整 */
@media (max-width: 1200px) {
    .card {
        flex: 0 0 calc(50% - 20px);
        max-width: calc(50% - 20px);
    }
}

@media (max-width: 768px) {
    .card {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.card-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
}

.text-content {
    flex: 1;
    margin-right: 20px;
}

.title {
    font-size: 16px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 8px;
    text-align: left;
}

.description {
    font-size: 14px;
    color: #666;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
}

.image-container {
    width: 80px;
    height: 80px;
    border-radius: 10px;
    overflow: hidden;
    flex-shrink: 0;
}

.image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.label {
    background-color: #f0f0f0;
    padding: 5px 10px;
    text-align: left;
    font-size: 12px;
    color: #666;
    border-radius: 5px;
    width: fit-content;
}

/* 免费样式 */
.label.free {
    color: #666;
}

/* 付费样式 */
.label.paid {
    color: #c00;
    font-weight: bold;
}

.footer {
    margin-top: 15px;
    font-size: 12px;
    color: #999;
    text-align: right;
}

/* 聊天框弹窗居中样式 */
.super-assistant-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 900px;
    background: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    z-index: 9999;
}

/* 其余样式 */
.assistant-container {
    display: flex;
    height: 100%;
}

.assistant-sidebar {
    width: 200px;
    padding-right: 10px;
}

.conversation-list {
    max-height: 480px;
    /* 设置最大高度为80vh，防止过高 */
    overflow-y: auto;
    /* 超出部分滚动 */
}


.assistant-main {
    flex: 1;
    display: flex;

    flex-direction: column;
    padding-left: 10px;
    border-left: 1px solid #ddd;
}

/* 保证标题和删除图标并排显示 */
.conversation-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* 使标题和删除图标垂直居中 */
    padding-left: 10px;
    padding-right: 10px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    border-radius: 5px;
}

/* 删除图标样式 */
.delete-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

/* 选中会话的效果 */
.conversation-item.active {
    background-color: #f0f0f0;
    border-left: 4px solid #6a11cb;
    /* 设置选中时的左边框 */
}

.chat-content {
    height: 400px;
    /* 固定高度 */
    overflow-y: auto;
    overflow-x: auto;
    /* 超出内容时滚动 */
    padding: 10px;
    border: 1px solid #ddd;
    /* 添加边框 */
    border-radius: 8px;
    /* 圆角边框 */
    background-color: #f9f9f9;
}

.chat-message {
    position: relative;
    /* 必须设置为relative以便子元素定位 */

    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.chat-message.user {
    align-items: flex-end;
    /* 用户消息右对齐 */
}

.chat-message.assistant {
    align-items: flex-start;
    /* AI消息左对齐 */
}

.message-bubble {
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #e0f7fa;
    border-radius: 10px;
    max-width: 80%;
    word-wrap: break-word;
    margin: 5px 0;
    text-align: left;
}

.copy-button-container {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 10px;
    /* 将复制按钮移到气泡外 */
}

.copy-button {
    background-color: #6a11cb;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
}

.copy-button:hover {
    background-color: #2575fc;
}

.chat-message.user .message-bubble {
    background-color: #b2ebf2;
    /* 用户消息背景色 */
}

.chat-message.assistant .message-bubble {
    background-color: #fff;
    /* AI消息背景色 */
}

.chat-input-container {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
}

.input-wrapper {
    display: flex;
    flex: 1;
    gap: 10px;
}

.input-box {
    flex: 1;
}

.send-button {
    flex-shrink: 0;
}

/* 右上角关闭按钮 */
.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.close-button img {
    width: 20px;
    height: 20px;
    /* 控制关闭按钮图片的大小 */
}

.close-button:hover img {
    opacity: 0.7;
    /* 添加鼠标悬停时的效果 */
}

.chat-image {
    max-width: 100%;
    /* 最大宽度100% */
    max-height: 200px;
    /* 最大高度200px */
    object-fit: contain;
    /* 按比例缩放 */
    cursor: pointer;
    /* 鼠标悬停时显示为可点击 */
}

/* 弹窗图片样式 */
.el-dialog .el-dialog__body img {
    width: 100%;
    height: auto;
}

.upload-button {
    background-color: #2575fc;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
}

.upload-button:hover {
    background-color: #2575fc;
}
</style>