import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import store from './store';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';

const app = createApp(App)
app.use(router)
app.use(store);
// 使用 Element Plus 并设置中文语言环境，只需要调用一次
app.use(ElementPlus, { locale: zhCn });
app.use(ElementPlus)
app.mount('#app')
