<template>
  <div class="auth-page">
    <!-- 背景图片放在登录框的上方 -->
    <div class="top-image">
      <img src="https://jzguai01.oss-cn-shanghai.aliyuncs.com/uploads/images/20250123/20250123012021a7dc74198.png"
        alt="背景图片" />
    </div>

    <div class="auth-container">
      <!-- 登录或注册表单 -->
      <el-form :model="form" :rules="formRules" ref="authForm" class="auth-form">
        <!-- 登录账号标题 -->
        <div v-if="!isRegister" class="auth-title">登录账号</div>

        <!-- 注册账号标题 -->
        <div v-if="isRegister" class="auth-title">注册账号</div>

        <el-form-item label="账号" prop="account">
          <el-input v-model="form.account" placeholder="请输入账号" />
        </el-form-item>

        <el-form-item label="密码" prop="password">
          <el-input v-model="form.password" type="password" placeholder="请输入密码" />
        </el-form-item>

        <!-- 注册时：确认密码 -->
        <el-form-item v-if="isRegister" label="确认密码" prop="confirmPassword">
          <el-input v-model="form.confirmPassword" type="password" placeholder="确认密码" />
        </el-form-item>

        <!-- 注册时：邀请码 -->
        <el-form-item v-if="isRegister" label="邀请码" prop="inviteCode">
          <el-input v-model="form.inviteCode" placeholder="请输入邀请码" />
        </el-form-item>

        <!-- 登录或注册按钮 -->
        <el-form-item>
          <el-button type="primary" class="auth-button" @click="handleSubmit" :disabled="!isFormValid">
            {{ isRegister ? '注册' : '登录' }}
          </el-button>

        </el-form-item>

        <span @click="toggleForm" class="toggle-link">
          {{ isRegister ? '已有账号？点击登录' : '没有账号？点击注册' }}
        </span>
      </el-form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'; // 导入 axios
import config from "@/config.js"; // 导入配置文件

export default {
  data() {
    return {
      isRegister: false, // 切换登录和注册
      form: {
        account: '', // 将 username 改为 account
        password: '',
        confirmPassword: '', // 注册时确认密码
        inviteCode: '', // 注册时邀请码
      },
      isFormValid: false,  // 新增数据属性

      // 表单验证规则
      formRules: {
        account: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          { min: 6, message: '账号长度不能小于6位', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, message: '密码长度不能小于6位', trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, message: '请确认密码', trigger: 'blur' },
          { min: 6, message: '确认密码长度不能小于6位', trigger: 'blur' }
        ],
        inviteCode: [
          { required: true, message: '请输入邀请码', trigger: 'blur' }
        ]
      }
    };
  },
  computed: {
    // 移除异步操作，直接返回 true
    // isFormValid() {
    //   return true;  // 不再计算异步验证
    // }
  },
  watch: {
    // 监听表单的变化并验证
    form: {
      handler() {
        this.validateForm();  // 触发表单验证
      },
      deep: true
    }
  },
  methods: {

    validateForm() {
      this.$refs.authForm.validate(valid => {
        this.isFormValid = valid;
      });
    },
    // 发送登录或注册请求
    handleSubmit() {
      const url = `${config.BASE_URL}/pc/api/login.php`; // 登录接口

      // 准备要发送的数据
      const data = {
        account: this.form.account, // 使用 account
        password: this.form.password,
        action: this.isRegister ? 'register' : 'login', // 判断操作类型
      };

      if (this.isRegister) {
        // 如果是注册，加入额外的数据
        data.confirmPassword = this.form.confirmPassword;
        data.inviteCode = this.form.inviteCode;
      }

      // 发送请求
      axios.post(url, data)
        .then(response => {
          const res = response.data;

          if (res.success) {
            // 登录或注册成功，进行相应操作
            if (!this.isRegister) {
              // 登录成功，保存用户数据到 localStorage
              this.$store.dispatch('login', res.data); // 提交用户数据到 Vuex
              this.$router.push({ name: 'HomePage' });
            } else {
              // 注册成功
              this.$message.success('注册成功');
              this.isRegister = false; // 切换到登录页面
              this.form.account = '';
              this.form.password = '';
              this.form.confirmPassword = '';
              this.form.inviteCode = '';
            }
          } else {
            // 错误处理
            this.$message.error(res.error || res.message);
          }
        })
        .catch(error => {
          console.error('请求失败', error);
          this.$message.error('请求失败，请稍后再试');
        });
    },
    toggleForm() {
      this.isRegister = !this.isRegister;
      // 清空表单
      this.form.account = '';
      this.form.password = '';
      this.form.confirmPassword = '';
      this.form.inviteCode = '';
    }
  },
  mounted() {
    // 确保 form 已经挂载
    this.$nextTick(() => {
      console.log(this.$refs.authForm);  // 用于检查 ref 是否成功
    });
  }
}
</script>

<style scoped>
.auth-page {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  /* 让内容垂直堆叠 */
}

.top-image {
  width: 450px;
  height: 250px;
  overflow: hidden;
}

.auth-container {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 400px;
  /* 最大宽度 */
  background: #ffffff;
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.auth-form {
  display: flex;
  flex-direction: column;
}

.el-form-item {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  /* 强制每个表单项分列显示 */
  text-align: left;
  /* 确保表单项内容左对齐 */
}

.el-form-item label {
  display: block;
  /* 标签单独占一行 */
  font-size: 16px;
  /* 调整标签字体大小 */
  font-weight: bold;
  margin-bottom: 10px;
  text-align: left;
  /* 确保标签左对齐 */
}

.el-input {
  width: 100%;
  font-size: 16px;
  /* 调整输入框字体大小 */
  height: 40px;
  /* 调整输入框的高度 */
}

.auth-button {
  width: 100%;
  padding: 12px;
  font-size: 16px;
}

.toggle-link {
  color: #409eff;
  cursor: pointer;
  text-align: center;
  display: block;
  margin-top: 10px;
  font-size: 14px;
}

.toggle-link:hover {
  text-decoration: underline;
}

.auth-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

/* 使用 ::v-deep 来深度选择 */
::v-deep .el-form-item__label {
  justify-content: flex-start;
  /* 确保标签左对齐 */
  text-align: left;
  /* 确保标签文本左对齐 */
}
</style>
