<template>
    <div class="user-page">
        <!-- 顶部容器 -->
        <div class="top-container">
            <!-- 左侧：头像和用户信息 -->
            <div class="left-panel">
                <div class="avatar-container">
                    <img :src="userInfo.avatar" alt="User Avatar" class="avatar" />
                </div>
                <div class="user-details">
                    <h2 class="nickname">{{ userInfo.nickname }}</h2>
                    <p class="ip-location">IP属地：{{ userInfo.ipLocation }}</p>
                </div>
            </div>


            <!-- 右侧：粉丝数、关注数、获赞数 -->
            <div class="right-panel">
                <div class="stats">
                    <div class="stat">
                        <p class="stat-title">粉丝数</p>
                        <p class="stat-value">{{ userInfo.fansCount }}</p>
                    </div>
                    <div class="stat">
                        <p class="stat-title">关注数</p>
                        <p class="stat-value">{{ userInfo.followingCount }}</p>
                    </div>
                    <div class="stat">
                        <p class="stat-title">获赞数</p>
                        <p class="stat-value">{{ userInfo.likesCount }}</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- 下面的整体板块 -->
        <div class="content-section">
            <!-- 用户视频 -->
            <div class="videos-section">
                <div class="header">
                    <h2 style="text-align: left">用户视频</h2>

                    <!-- 右侧增加搜索栏 -->
                    <div class="search-container">
                        <input v-model="searchQuery" type="text" placeholder="搜索视频标题..." class="search-input" />
                    </div>
                </div>


                <div class="videos-grid">
                    <div v-for="(video, index) in filteredVideos" :key="index" class="video-card">
                        <div class="video-cover-container" style="position: relative;">
                            <img :src="video.cover_image" alt="Video Cover" class="video-cover" />
                            <button @click="parseVideo(video.original_link)" class="parse-button">解析</button>
                            <button @click="createContent(video)" class="create-button">创作</button>
                        </div>
                        <div class="video-info">
                            <h4 class="video-title">{{ truncateText(video.video_title, 12) }}</h4>
                            <p class="video-description">{{ truncateText(video.video_title, 14) }}</p>
                            <div class="video-stats">
                                <p>点赞数：{{ video.likes_count }}</p>
                                <button @click="goToVideo(video.original_link)" class="go-button">直达</button>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- 分页 -->
                <div class="pagination">
                    <button @click="changePage(currentPage - 1)" :disabled="currentPage <= 1">上一页</button>
                    <span>第 {{ currentPage }} 页 / 共 {{ totalPages }} 页</span>
                    <button @click="changePage(currentPage + 1)" :disabled="currentPage >= totalPages">下一页</button>
                </div>
            </div>

            <!-- 文案复刻 和 抖音号分析报告竖排布局 -->
            <div class="copywriting-analysis">
                <!-- 文案复刻 -->
                <div class="copywriting-section">
                    <h2 style="text-align: left">文案复刻</h2>

                    <textarea ref="copywritingInput" class="copywriting-input" placeholder="请输入文案..."></textarea>

                    <!-- 添加点击事件，触发新的方法 -->
                    <button class="generate-button" @click="generateCopywriting">立即生成优质文案</button>
                </div>


                <!-- 抖音号分析报告 -->
                <div class="analysis-section">
                    <div class="header">
                        <h2>抖音号分析报告</h2>
                        <div class="action-buttons">
                            <button class="copy-button" @click="copyToClipboards">复制</button>
                            <div class="export-dropdown">
                                <button class="export-button">导出</button>
                                <div class="dropdown-content">
                                    <button @click="exportImage">导出为图片</button>
                                    <button class="export-button" @click="exportPDF">导出为PDF</button>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="analysis-report">
                        <p v-html="analysisReport"></p>
                    </div>
                </div>




                <!-- 动态加载遮罩层 -->
                <div v-if="isLoading" class="loading-overlay">
                    <div class="loading-spinner"></div> <!-- 加载动画 -->
                </div>
                <!-- 弹窗显示解析结果 -->
                <div v-if="showModal" class="modal">
                    <div class="modal-content">
                        <span class="modal-header">
                            <!-- 重新解析图标 -->
                            <span class="reprocess-icon" @click="reprocessVideo" title="重新解析">
                                <img src="@/assets/cxjx.png" alt="Reprocess Icon" class="reprocess-icon-img" />
                            </span>

                            <!-- 关闭按钮 -->
                            <span class="close" @click="closeModal" title="关闭弹窗">&times;</span>

                        </span>


                        <div class="modal-body">
                            <!-- 左侧：视频封面 -->
                            <div class="modal-left">
                                <img :src="modalData.task_cover || ''" alt="Video Cover" class="modal-cover" />
                            </div>

                            <!-- 右侧：视频信息 -->
                            <div class="modal-right">
                                <h3>{{ modalData.task_title || '标题未提供' }}</h3>

                                <div class="modal-info">

                                    <!-- 第一行：作者 和 点赞数 -->
                                    <div class="info-line">
                                        <div class="info-box"><strong>作者：</strong>{{ modalData.task_author || '作者未提供' }}
                                        </div>
                                        <div class="info-box"><strong>点赞数：</strong>{{ modalData.task_like || 0 }}</div>
                                    </div>

                                    <!-- 第二行：UID 和 评论数 -->
                                    <div class="info-line">
                                        <div class="info-box"><strong>UID：</strong>{{ modalData.task_uid || '未提供' }}
                                        </div>
                                        <div class="info-box"><strong>评论数：</strong>{{ modalData.task_comment || 0 }}
                                        </div>
                                    </div>

                                    <!-- 第三行：时间 和 收藏数 -->
                                    <div class="info-line">
                                        <div class="info-box"><strong>时间：</strong>{{
                                            formatTimestamp(modalData.task_time) || '未提供' }}</div>
                                        <div class="info-box"><strong>收藏数：</strong>{{ modalData.task_collect || 0 }}
                                        </div>
                                    </div>

                                    <!-- 可选行：分享数 -->
                                    <div class="info-line">
                                        <div class="info-box"><strong>分享数：</strong>{{ modalData.task_share || 0 }}</div>
                                        <div class="info-boxs"></div>
                                    </div>



                                    <!-- 下载按钮 -->
                                    <div class="download-buttons">
                                        <div v-if="modalData.task_url">
                                            <button @click="downloadVideo(modalData.task_url)"
                                                class="custom-button">下载视频</button>
                                        </div>
                                        <div v-if="modalData.task_music_url">
                                            <button @click="downloadMusic(modalData.task_music_url)"
                                                class="custom-button">下载音乐</button>
                                        </div>
                                        <div v-if="modalData.task_music_cover">
                                            <button @click="downloadMusicCover(modalData.task_music_cover)"
                                                class="custom-button">下载音乐封面</button>
                                        </div>
                                        <div v-if="modalData.task_images && modalData.task_images.length > 0">
                                            <button @click="downloadImages(modalData.task_images)"
                                                class="custom-button">下载图片</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- 自定义弹窗显示解析结果 -->
                <div v-if="isCustomModalVisible" class="custom-modal">
                    <div class="custom-modal-content">
                        <!-- 关闭按钮移到右下角 -->
                        <span class="custom-close" @click="closeCustomModal" title="关闭弹窗">&times;</span>

                        <div class="custom-modal-body">
                            <h3>标题：</h3>
                            <p style="font-size: 18px;">{{ modalData.task_title }}</p>

                            <h3>文案内容：</h3>
                            <p style="font-size: 16px;">{{ modalData.task_wenan }}</p>

                            <!-- 立即创作按钮固定在底部 -->
                            <button class="custom-buttons" @click="fillTextarea">立即创作</button>
                        </div>
                    </div>
                </div>

                <div v-if="isNewCustomModalVisible" class="new-custom-modal"> <!-- 修改为新的样式类 -->
                    <div class="new-custom-modal-content"> <!-- 修改为新的样式类 -->
                        <span class="new-custom-close" @click="closeNewCustomModal" title="关闭弹窗">&times;</span>
                        <div class="new-custom-modal-body">
                            <h3>标题：</h3>
                            <p style="font-size: 18px;">{{ newModalData.task_title }}</p>

                            <h3>文案内容：</h3>
                            <p style="font-size: 16px;">{{ newModalData.task_wenan }}</p>

                            <h3 style="font-size: 18px;">话题：</h3>
                            <p>{{ newModalData.task_topic }}</p>

                            <button class="new-custom-button" @click="copyToClipboard">一键复制</button>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import config from "@/config.js";  // 引入config配置
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';

import html2pdf from 'html2pdf.js';


export default {
    props: ['taskId', 'Id'],  // 接收通过路由传递的 taskId 和 Id

    data() {
        return {
            taskData: null,  // 存储任务数据
            userInfo: {
                avatar: 'https://p3-pc.douyinpic.com/aweme/1080x1080/aweme-avatar/tos-cn-avt-0015_8d34b3a441af8508726ed8ed1f5137a7.jpeg?from=2956013662',
                nickname: '张琦老师-商业咨询',
                ipLocation: '广东',
                fansCount: 5899179,
                followingCount: 30,
                likesCount: 1200000,
            },
            searchQuery: '', // 搜索关键词
            userVideos: [],  // 存储所有视频数据
            currentPage: 1,  // 当前页
            videosPerPage: 10,  // 每页显示视频数
            totalPages: 0,  // 总页数
            copywritingContent: '这里是文案复刻内容。',
            analysisReport: '这里是抖音号分析报告内容。',
            showModal: false,  // 控制弹窗显示
            modalData: '',  // 弹窗数据
            isLoading: false,  // 控制加载动画显示
            isCustomModalVisible: false,
            newModalData: {  // 修改为独立的数据对象
                task_title: '',
                task_wenan: '',
                task_topic: '',
            },
            isNewCustomModalVisible: false,  // 修改为独立的弹窗显示控制

        };
    },
    mounted() {
        this.checkLoginStatus(); // 页面加载时检查登录状态
        this.fetchTaskData();
    },
    computed: {
        // 根据当前页和每页数量返回显示的视频数据
        paginatedVideos() {
            const startIndex = (this.currentPage - 1) * this.videosPerPage;
            const endIndex = startIndex + this.videosPerPage;
            return this.userVideos.slice(startIndex, endIndex);
        },
        // 根据当前页和每页数量返回显示的视频数据
        filteredVideos() {
            // 过滤出标题匹配搜索条件的视频
            const filtered = this.userVideos.filter(video =>
                video.video_title.toLowerCase().includes(this.searchQuery.toLowerCase())
            );
            const startIndex = (this.currentPage - 1) * this.videosPerPage;
            const endIndex = startIndex + this.videosPerPage;
            return filtered.slice(startIndex, endIndex); // 返回分页后的结果
        }
    },
    methods: {

        // 检查用户是否登录
        checkLoginStatus() {
            const userId = this.$store.state.userId;
            if (!userId) {
                this.goToLogin();  // 如果没有 userId，跳转到登录页面
            }
        },

        // 跳转到登录页面
        goToLogin() {
            this.$router.push({ name: 'LoginPage' });
        },
        goToVideo(link) {
            window.open(link, '_blank');
        },
        formatTimestamp(timestamp) {
            if (!timestamp) return '未提供';
            const date = new Date(timestamp * 1000);  // 时间戳转为毫秒
            return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
        },
        // 用来判断并截取文本
        truncateText(text, limit) {
            if (text.length > limit) {
                return text.slice(0, limit) + '...';
            }
            return text;
        },
        showNewCustomModal() {
            this.isNewCustomModalVisible = true;
        },

        closeNewCustomModal() {
            this.isNewCustomModalVisible = false;
        },
        // 复制内容到剪贴板
        copyToClipboards() {
            // Remove HTML tags from the analysisReport content
            const textToCopy = this.analysisReport.replace(/<[^>]*>/g, '');

            // Attempt to copy the cleaned text to clipboard
            navigator.clipboard.writeText(textToCopy)
                .then(() => this.$message.success("已成功复制到剪贴板！"))
                .catch(() => this.$message.error("复制失败，请重试"));
        },


        // 导出为 PDF
        exportPDF() {
            const content = document.querySelector('.analysis-report p'); // 你要导出的内容

            const options = {
                margin: [20, 20],
                filename: `${this.userInfo.nickname} 分析报告.pdf`,
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2, useCORS: true },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
            };

            html2pdf().from(content).set(options).save();
        },



        // 导出为图片
        exportImage() {
            const element = document.querySelector('.analysis-report p');

            // 通过 inline style 给元素添加 padding，确保内容不会太靠近边缘
            element.style.padding = '20px'; // 增加内边距

            html2canvas(element).then((canvas) => {
                canvas.toBlob((blob) => {
                    // 修改导出的文件名为 用户昵称 + "分析报告.png"
                    const fileName = `${this.userInfo.nickname} 分析报告.png`;
                    saveAs(blob, fileName);
                });
            });
        },

        copyToClipboard() {
            const textToCopy = `标题: ${this.newModalData.task_title}\n文案内容: ${this.newModalData.task_wenan}\n话题: ${this.newModalData.task_topic}`;
            navigator.clipboard.writeText(textToCopy)
                .then(() => this.$message.success("已复制到剪贴板！"))
                .catch(() => this.$message.error("复制失败，请重试"));

        },
        generateCopywriting() {
            this.isLoading = true;  // 显示加载动画
            const message = this.$refs.copywritingInput.value; // 获取输入框的内容
            const wa_type = "模仿文案";  // 默认文案类型
            const user_id = this.$store.state.userId; // 从 Vuex 获取用户ID
            const task_id = this.taskId; // 从路由获取taskId
            const nickname = this.userInfo.nickname; // 获取用户昵称

            // 校验输入是否为空
            if (!message.trim()) {
                this.$message.error("请输入文案内容！");
                return;
            }

            // 调用接口，传递数据到 create_wenan_history.php
            axios.post(`${config.BASE_URL}/pc/api/create_wenan_history.php`, {
                message,
                wa_type,
                user_id,
                task_id,
                nickname
            })
                .then(response => {
                    // 检查接口返回的status字段
                    if (response.data.status === "success") {

                        // 将返回的数据赋值到 newModalData
                        this.newModalData.task_title = response.data.title;
                        this.newModalData.task_wenan = response.data.content;
                        this.newModalData.task_topic = response.data.topic;
                        this.isLoading = false;  // 显示加载动画
                        this.isNewCustomModalVisible = true; // 显示新弹窗
                        this.$message.success("文案生成成功！");

                    } else {
                        this.$message.error("文案生成失败，请重试！");
                        this.isLoading = false;  // 显示加载动画
                    }
                })
                .catch(error => {
                    console.error("接口调用失败", error);
                    this.$message.error("请求失败，请稍后再试");
                    this.isLoading = false;  // 显示加载动画
                });
        },

        createContent(video) {
            this.isLoading = true;  // 显示加载遮罩层

            // 传递 video.original_link 到 dy_url
            const dyUrl = video.original_link;

            // 调用解析视频函数并等待其完成
            this.parseVideos(dyUrl).then(() => {
                // 解析视频成功后，再请求 get_task_wenan.php 接口
                const url = `${config.BASE_URL}/pc/api/get_task_wenan.php`;

                axios.post(url, {
                    dy_url: dyUrl  // 使用 video.original_link 作为 dy_url
                })
                    .then(response => {
                        if (response.data && response.data.task_title && response.data.task_wenan) {
                            // 请求成功，处理返回的数据
                            const taskTitle = response.data.task_title;
                            const taskWenan = response.data.task_wenan;

                            // 处理任务标题和文案内容
                            this.modalData.task_title = taskTitle;
                            this.modalData.task_wenan = taskWenan;
                            this.$message.success("文案获取成功！");
                            // 成功后显示弹窗
                            this.isCustomModalVisible = true;
                            this.isLoading = false;
                        } else {
                            this.isLoading = false;
                            this.isCustomModalVisible = false;
                            this.$message.error("文案生成失败, 请先解析视频，在获取文案！");
                        }
                    })
                    .catch(error => {
                        this.isLoading = false;
                        this.isCustomModalVisible = false;
                        console.error("请求失败:", error);
                        this.$message.error("请求失败，请稍后再试");
                    })
                    .finally(() => {
                        this.isLoading = false;  // 隐藏加载遮罩层

                    });
            }).catch(error => {
                this.isLoading = false;  // 隐藏加载遮罩层
                this.isCustomModalVisible = false;
                console.error("请求失败:", error);
                this.$message.error("视频解析失败，请稍后再试");
            });
        },

        // 修改 parseVideos 函数为返回 Promise
        parseVideos(dyUrl) {
            return new Promise((resolve, reject) => {
                const userId = this.$store.state.userId;  // 从 Vuex 获取用户ID
                const taskId = this.taskId;  // 使用从路由传递的 task_id

                const url = `${config.BASE_URL}/pc/api/dy_video_insert.php`;

                axios.post(url, {
                    dy_url: dyUrl,
                    user_id: userId,
                    task_id: taskId,
                })
                    .then(response => {
                        if (response.data && response.data.data) {
                            const data = response.data.data;
                            data.task_images = Array.isArray(data.task_images) ? data.task_images : JSON.parse(data.task_images || '[]');

                            console.log(data);  // 调试输出

                            this.modalData = data;
                            resolve();  // 解析视频成功，继续执行
                        } else {
                            reject("解析视频失败");  // 解析失败，拒绝
                        }
                    })
                    .catch(error => {
                        console.error('请求失败:', error);
                        reject(error);  // 请求失败，拒绝
                    });
            });
        },

        // 填充文案到输入框
        fillTextarea() {
            if (this.$refs.copywritingInput) {
                const text = `标题：\n${this.modalData.task_title}\n\n文案内容：\n${this.modalData.task_wenan}`;
                this.$refs.copywritingInput.value = text;  // 确保 this.$refs.copywritingInput 存在
                this.closeCustomModal();  // 关闭弹窗
            } else {
                console.error("textarea not found");
            }
        },


        // 显示自定义弹窗
        showCustomModal() {
            this.isCustomModalVisible = true;
        },

        closeCustomModal() {
            this.isCustomModalVisible = false;
        },

        // 请求任务数据
        fetchTaskData() {
            const userId = this.$store.state.userId;  // 从 Vuex 获取用户ID
            const taskId = this.taskId;  // 使用从路由传递的 task_id
            const Id = this.Id;  // 使用从路由传递的 id

            const url = `${config.BASE_URL}/pc/api/get_user_task.php`;

            axios.get(url, {
                params: {
                    user_id: userId,
                    task_id: taskId,
                    id: Id,
                },
            })
                .then(response => {
                    if (response.data && response.data.task) {
                        const taskData = response.data.task;

                        if (response.data.task.ip_content) {
                            this.analysisReport = response.data.task.ip_content;
                        } else {
                            this.analysisReport = '内容正在获取中····'; // 或者可以设置为其他默认值
                        }

                        // 更新任务数据
                        this.taskData = taskData;

                        // 更新用户信息，提取并赋值
                        const userData = JSON.parse(taskData.fx_content).user_data;
                        this.userInfo = {
                            avatar: userData.profile_picture,
                            nickname: userData.nickname,
                            ipLocation: userData.ip_location,
                            fansCount: userData.followers_count,
                            followingCount: userData.following_count,
                            likesCount: userData.likes_count,
                        };

                        // 提取视频数据并赋值
                        this.userVideos = JSON.parse(taskData.fx_content).videos;

                        // 计算总页数
                        this.totalPages = Math.ceil(this.userVideos.length / this.videosPerPage);
                    } else {
                        console.error('任务数据为空');
                    }
                })
                .catch(error => {
                    console.error('请求失败:', error);
                });
        },

        // 切换页数
        changePage(page) {
            if (page >= 1 && page <= this.totalPages) {
                this.currentPage = page;
            }
        },

        // 解析视频并弹出显示结果
        parseVideo(dyUrl) {
            this.isLoading = true;  // 显示加载动画
            const userId = this.$store.state.userId;  // 从 Vuex 获取用户ID
            const taskId = this.taskId;  // 使用从路由传递的 task_id

            const url = `${config.BASE_URL}/pc/api/dy_video_insert.php`;

            axios.post(url, {
                dy_url: dyUrl,
                user_id: userId,
                task_id: taskId,
            })
                .then(response => {
                    if (response.data && response.data.data) {
                        // 确保 task_images 是一个有效数组
                        const data = response.data.data;
                        data.task_images = Array.isArray(data.task_images) ? data.task_images : JSON.parse(data.task_images || '[]');

                        // 调试输出
                        console.log(data);  // 这里查看接口返回的完整数据

                        // 设置延迟1秒后再显示弹窗
                        setTimeout(() => {
                            this.modalData = data;
                            this.showModal = true;
                            this.isLoading = false;  // 隐藏加载动画
                        }, 1000); // 延迟1秒（1000毫秒）
                    } else {
                        console.error('接口返回数据为空');
                        this.isLoading = false;  // 隐藏加载动画
                    }
                })
                .catch(error => {
                    console.error('请求失败:', error);
                    this.isLoading = false;  // 隐藏加载动画
                });
        },
        // 重新解析视频
        reprocessVideo() {
            this.isLoading = true;  // 显示加载动画
            const userId = this.$store.state.userId;  // 从 Vuex 获取用户ID
            const taskId = this.taskId;  // 使用从路由传递的 task_id

            const url = `${config.BASE_URL}/pc/api/dy_video_insert.php`;

            // 传递 reprocess: true
            axios.post(url, {
                dy_url: this.modalData.dy_url,
                user_id: userId,
                task_id: taskId,
                reprocess: true  // 添加重新解析的标识
            })
                .then(response => {

                    if (response.data && response.data.data) {
                        // 确保 task_images 是一个有效数组
                        const data = response.data.data;
                        data.task_images = Array.isArray(data.task_images) ? data.task_images : JSON.parse(data.task_images || '[]');

                        // 调试输出
                        console.log(data);  // 这里查看接口返回的完整数据

                        // 设置延迟1秒后再显示弹窗
                        setTimeout(() => {
                            this.modalData = data;
                            this.isLoading = false;  // 隐藏加载动画
                        }, 1000); // 延迟1秒（1000毫秒）
                    } else {
                        console.error('接口返回数据为空');
                        this.isLoading = false;  // 隐藏加载动画
                    }
                })
                .catch(error => {
                    console.error('请求失败:', error);
                    this.isLoading = false;  // 隐藏加载动画
                });
        },

        // 关闭弹窗
        closeModal() {
            this.showModal = false;
        },
        // 下载视频
        downloadVideo(url) {
            window.open(url, '_blank');
        },

        // 下载音乐
        downloadMusic(musicUrl) {
            window.open(musicUrl, '_blank');
        },

        // 下载音乐封面
        downloadMusicCover(musicCover) {
            window.open(musicCover, '_blank');
        },


        // 下载图片
        downloadImages(images) {
            images.forEach(imageUrl => {
                window.open(imageUrl, '_blank');
            });
        }

    },
};
</script>

<style scoped>
.user-page {
    font-family: Arial, sans-serif;
    padding: 20px;
}

.top-container {
    display: flex;
    justify-content: space-between;
    /* 确保左侧和右侧内容分布在两端 */
    width: 60%;
    /* 设置顶部容器的宽度 */
    margin: 0 auto;
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
}

.left-panel {
    display: flex;
    align-items: center;
    width: 60%;
    /* 左侧部分占用20%的宽度 */
}

.right-panel {
    display: flex;
    justify-content: flex-end;
    /* 右侧内容右对齐 */
    align-items: center;
    width: 40%;
    /* 右侧部分占用50%的宽度 */
}

/* 增加右侧面板与左侧面板之间的间距 */
.stats {
    display: flex;
    justify-content: space-between;
    width: 100%;
}



.avatar-container {
    width: 80px;
    /* 设置头像的宽度 */
    height: 80px;
    /* 设置头像的高度 */
    margin-right: 10px;
    /* 给头像和昵称之间增加间距 */
    overflow: hidden;
    border-radius: 50%;
}

.avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.user-details {
    display: flex;
    flex-direction: column;
    /* 垂直排列昵称和IP */
    justify-content: flex-start;
}

.nickname {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    text-align: left;
    /* 去除默认的 margin */
}

.ip-location {
    font-size: 14px;
    color: #777;
    margin-top: 15px;
    text-align: left;
    /* 确保文本左对齐 */
}


.right-panel {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: right;
}


.stat {
    text-align: center;
}

.stat-title {
    font-size: 16px;
    color: #555;
}

.stat-value {
    font-size: 20px;
    font-weight: bold;
    color: #333;
}

.content-section {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    width: 62%;
    /* 保持与顶部板块一致的宽度 */
    margin: 0 auto;
}

.videos-section {

    background-color: white;
    border-radius: 8px;

}


.copywriting-section {
    padding: 0px 20px 20px 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: left;
    margin-left: 20px;
}

.copywriting-input {
    width: 96%;
    /* 确保输入框与容器宽度一致 */
    height: 150px;
    /* 设置高度 */
    resize: none;
    /* 禁止拉伸输入框 */
    padding: 10px;
    /* 输入框内边距 */
    margin-top: 10px;
    /* 输入框和上方内容之间的间距 */
    border: 1px solid #ccc;
    /* 输入框边框 */
    border-radius: 8px;
    /* 圆角 */
    font-size: 14px;
    /* 字体大小 */
    outline: none;
    /* 去掉默认聚焦时的边框 */
}

.copywriting-input:focus {
    border-color: #007bff;
    /* 聚焦时改变输入框边框颜色 */
}

.generate-button {
    width: 100%;
    /* 按钮宽度与输入框一致 */
    padding: 10px;
    /* 按钮内边距 */
    margin-top: 20px;
    /* 按钮与输入框之间的间距 */
    background: linear-gradient(135deg, #6a11cb, #2575fc);
    /* 流光渐变效果 */
    color: white;
    /* 按钮文字颜色 */
    border: none;
    /* 去掉默认边框 */
    border-radius: 8px;
    /* 圆角按钮 */
    font-size: 16px;
    /* 字体大小 */
    cursor: pointer;
    /* 鼠标悬停时显示手指光标 */
    transition: background 0.3s ease, transform 0.3s ease;
    /* 添加过渡效果 */
}

.generate-button:hover {
    background: linear-gradient(135deg, #2575fc, #6a11cb);
    /* 流光渐变的方向反转 */
    transform: scale(1.05);
    /* 按钮放大一点 */
}


.analysis-section {
    padding: 0px 20px 20px 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: left;
    margin-left: 20px;
}

.videos-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.video-card {
    background-color: #fff;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
}

.video-cover {
    width: 220px;
    height: 280px;
    object-fit: cover;
    border-radius: 8px;
}

.video-info {
    padding: 10px 0;
}

.video-title {
    font-size: 16px;
    font-weight: bold;
    text-align: left;
}

.video-description {
    font-size: 14px;
    color: #777;
    text-align: left;
}

.video-stats {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.go-button {
    background: linear-gradient(135deg, #6a11cb, #2575fc);
    /* 流光渐变效果 */
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    min-width: 80px;
    /* 设置最小宽度 */
    text-align: center;
    /* 确保文本居中 */
    transition: background 0.3s ease, transform 0.3s ease;
    /* 添加过渡效果 */
}

/* 鼠标悬停时的效果 */
.go-button:hover {
    background: linear-gradient(135deg, #2575fc, #6a11cb);
    /* 流光渐变的方向反转 */
    transform: scale(1.05);
    /* 按钮放大一点 */
}

.copywriting-content {
    font-size: 14px;
    color: #555;
}

.analysis-report {

    font-size: 14px;
    color: #555;
}


.analysis-report {
    position: relative;
}

.report-header {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
}

.copy-button,
.export-button {
    background-color: #007bff;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.copy-button:hover,
.export-button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

.export-dropdown {
    position: relative;
}

.export-button:hover {
    background-color: #45a049;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 4px;
}

.export-dropdown:hover .dropdown-content {
    display: block;
}

.dropdown-content button {
    background-color: #f9f9f9;
    color: black;
    padding: 12px 16px;
    text-align: left;
    border: none;
    width: 100%;
    cursor: pointer;
    border-radius: 4px;
}

.dropdown-content button:hover {
    background-color: #ddd;
}

.copywriting-analysis {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination button {
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.pagination span {
    align-self: center;
    margin: 0 10px;
    font-size: 14px;
}

/* 解析按钮样式 */
.parse-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgb(234 179 8 / var(--tw-bg-opacity, 1));
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;

}

.parse-button:hover {
    background-color: rgb(234 179 8 / var(--tw-bg-opacity, 1));
}

/* 弹窗背景 */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* 确保弹窗处于最前面 */
}

/* 弹窗内容 */
.modal-content {
    background: linear-gradient(135deg, #a1c4fd, #c2e9fb);
    /* 浅蓝到白色流光渐变 */
    padding: 20px;
    border-radius: 12px;
    display: flex;
    width: 1200px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    position: relative;
    /* 确保子元素相对于它定位 */
}



/* 弹窗内容布局 */
.modal-body {
    display: flex;
    width: 100%;
    gap: 20px;
    align-items: center;
}

/* 左侧封面 */
.modal-left {
    flex: 1;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
}

.modal-cover {
    width: 100%;
    max-width: 250px;

    height: 300px;
    object-fit: cover;
    border-radius: 12px;
}

/* 右侧视频信息 */
.modal-right {
    flex: 2;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}




.modal-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* 行间距 */
}

.info-line {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 10px;
    /* 盒子之间的间距 */
}

.info-box {
    background-color: #f5f5f5;
    padding: 8px 12px;
    border-radius: 8px;
    text-align: left;
    flex: 1 1 45%;
    /* 每个盒子占一半的宽度 */
    min-width: 160px;
    /* 设置最小宽度 */
    max-width: 48%;
    /* 最大宽度为父容器的一半 */
    word-wrap: break-word;
    /* 防止内容过长时超出 */
}


.info-boxs {
    padding: 8px 12px;
    border-radius: 8px;
    text-align: left;
    flex: 1 1 45%;
    /* 每个盒子占一半的宽度 */
    min-width: 160px;
    /* 设置最小宽度 */
    max-width: 48%;
    /* 最大宽度为父容器的一半 */
    word-wrap: break-word;
    /* 防止内容过长时超出 */
}


.info-box strong {
    color: #333;
}


/* 按钮 */
.custom-button {
    background: linear-gradient(135deg, #6a11cb, #2575fc);
    /* 流光渐变色 */
    color: white;
    padding: 12px 24px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    transition: background 0.3s ease;
    width: 170px;
    margin: 10px 0;
}

.custom-button:hover {
    background: linear-gradient(135deg, #2575fc, #6a11cb);
    transform: scale(1.05);
}

.custom-close {
    font-size: 32px;
    color: white;
    cursor: pointer;
    background-color: transparent;
    border: none;
    font-weight: bold;
}

.download-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: flex-start;
}

/* 弹窗头部 - 放置在右上角 */
.modal-header {
    position: absolute;
    /* 绝对定位 */
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
}

/* 重新解析图标 */
.reprocess-icon {
    font-size: 20px;
    color: #007bff;
    margin-right: 10px;
    /* 让图标和关闭按钮之间有间距 */
    cursor: pointer;
    display: flex;
    align-items: center;
}

/* 小图标的样式 */
.reprocess-icon-img {
    width: 18px;
    /* 控制图标大小 */
    height: 18px;
    margin-right: 5px;
    /* 图标与文字之间留空 */
}

/* 鼠标悬停时颜色变化 */
.reprocess-icon:hover {
    color: #0056b3;
}

.custom-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
}

.custom-modal-content {
    background: linear-gradient(135deg, #a1c4fd, #c2e9fb);
    padding: 20px;
    border-radius: 12px;
    width: 600px;
    max-height: 80%;
    /* 设置最大高度 */
    overflow-y: auto;
    /* 让内容超出时显示滚动条 */
    position: relative;
}

/* 关闭按钮位置 */
.custom-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    color: white;
    cursor: pointer;
}

/* 内容部分，确保它靠左对齐 */
.custom-modal-body {
    text-align: left;
    margin-bottom: 60px;
    /* 给底部的按钮留出空间 */
}

.custom-modal-body h3 {
    margin-bottom: 8px;
    font-size: 18px;
    font-weight: bold;
}

.custom-modal-body p {
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 1.5;
    max-height: 300px;
    overflow-y: auto;
    /* 超过内容时显示滚动条 */
    scrollbar-width: thin;
    /* Firefox 自定义滚动条宽度 */
    scrollbar-color: #2575fc #f1f1f1;
    /* Firefox 滚动条颜色 */
}

/* Webkit 浏览器（Chrome, Safari）自定义滚动条 */
.custom-modal-body p::-webkit-scrollbar {
    width: 8px;
    /* 滚动条宽度 */
}

.custom-modal-body p::-webkit-scrollbar-thumb {
    background-color: #2575fc;
    /* 滚动条颜色 */
    border-radius: 10px;
    /* 圆角 */
}

.custom-modal-body p::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* 滚动条轨道的颜色 */
}


/* 立即创作按钮固定在底部 */
.custom-buttons {
    background: linear-gradient(135deg, #6a11cb, #2575fc);
    color: white;
    padding: 12px 24px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    transition: background 0.3s ease;
    width: 95%;
    /* 设定宽度为 80%，可以根据需要调整 */
    max-width: 1000px;
    /* 最大宽度，可以根据需要设置 */
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    /* 使按钮水平居中 */
}

.custom-buttons:hover {
    background: linear-gradient(135deg, #2575fc, #6a11cb);
    transform: translateX(-50%);
    /* 使按钮水平居中 */
}



/* 关闭按钮 */
.close {
    font-size: 32px;
    color: white;
    cursor: pointer;
    background-color: transparent;
    border: none;
    font-weight: bold;
}

/* 遮罩层 */
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* 半透明背景 */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
    /* 确保遮罩层在其他内容之上 */
}

/* 动态加载图标样式 */
.loading-spinner {
    border: 4px solid #f3f3f3;
    /* 背景色 */
    border-top: 4px solid #3498db;
    /* 旋转颜色 */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
    /* 旋转动画 */
}

/* 旋转动画 */
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.create-button {
    position: absolute;
    top: 10px;
    right: 70px;
    /* 根据你的布局调整距离 */
    background-color: #28a745;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
}

.create-button:hover {
    background-color: #218838;
}

/* 新的样式名 */
.new-custom-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
}

.new-custom-modal-content {
    background: linear-gradient(135deg, #a1c4fd, #c2e9fb);
    padding: 20px;
    border-radius: 12px;
    width: 600px;
    max-height: 80%;
    overflow-y: auto;
    position: relative;
}

.new-custom-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    color: white;
    cursor: pointer;
}


.new-custom-modal-body {
    text-align: left;
    margin-bottom: 60px;
    max-height: calc(80vh - 80px);
    /* 设置最大高度，确保按钮固定在底部 */
}

.new-custom-modal-body p {
    max-height: 200px;
    /* 限制文案内容区域的最大高度 */
    overflow-y: auto;
    /* 启用垂直滚动 */
    margin: 0;
    /* 去掉上下的默认间距 */
    padding: 10px 0;
    /* 增加一些间距 */
    overflow-y: auto;
    /* 超过内容时显示滚动条 */
    scrollbar-width: thin;
    /* Firefox 自定义滚动条宽度 */
    scrollbar-color: #2575fc #f1f1f1;
}


/* Webkit 浏览器（Chrome, Safari）自定义滚动条 */
.new-custom-modal-body p::-webkit-scrollbar {
    width: 8px;
    /* 滚动条宽度 */
}

.new-custom-modal-body p::-webkit-scrollbar-thumb {
    background-color: #2575fc;
    /* 滚动条颜色 */
    border-radius: 10px;
    /* 圆角 */
}

.new-custom-modal-body p::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* 滚动条轨道的颜色 */
}

/* 其他样式不变 */
.new-custom-button {
    background: linear-gradient(135deg, #6a11cb, #2575fc);
    color: white;
    padding: 12px 24px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    transition: background 0.3s ease;
    width: 95%;
    max-width: 1000px;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
}

.new-custom-button:hover {
    background: linear-gradient(135deg, #2575fc, #6a11cb);
    transform: translateX(-50%);
    /* 使按钮水平居中 */
}

/* 搜索栏和标题在同一行 */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}


.action-buttons {
    display: flex;
    align-items: center;
    gap: 10px;
}

/* 搜索框样式 */
.search-container {
    display: flex;
    justify-content: flex-end;
}

.search-input {
    width: 200px;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 14px;
}
</style>