<template>
  <div class="profile-page">
    <!-- 个人中心容器 -->
    <div class="container">
      <div class="header">
        <h1>个人中心</h1>
        <div class="user-info">
          <p>已登录账号: <strong>{{ userEmail }}</strong></p>
        </div>
      </div>

      <!-- 按钮切换 -->
      <div class="switch-buttons" style="margin-top: 20px;">
        <el-button :type="activeView === 'score-records' ? 'primary' : 'default'" @click="switchView('score-records')">
          积分记录
        </el-button>
        <el-button :type="activeView === 'recharge' ? 'primary' : 'default'" @click="switchView('recharge')">
          积分充值
        </el-button>
        <el-button :type="activeView === 'change-password' ? 'primary' : 'default'"
          @click="switchView('change-password')">
          修改密码
        </el-button>
      </div>

      <!-- 内容区 -->
      <div class="content" style="margin-top: 30px;">
        <!-- 积分记录 -->
        <div v-if="activeView === 'score-records'">
          <div class="records">
            <el-table :data="records">
              <el-table-column prop="task_id" label="任务ID"></el-table-column>

              <!-- 类型：充值/消费 自定义渲染 -->
              <el-table-column label="类型">
                <template #default="scope">
                  <span
                    :class="{ 'type-recharge': scope.row.cost_type === '充值', 'type-consume': scope.row.cost_type === '消费' }">
                    {{ scope.row.cost_type }}
                  </span>
                </template>
              </el-table-column>

              <!-- 积分：正负不同颜色 -->
              <el-table-column label="积分">
                <template #default="scope">
                  <span
                    :class="{ 'points-positive': parseFloat(scope.row.dianshu) > 0, 'points-negative': parseFloat(scope.row.dianshu) < 0 }">
                    {{ scope.row.dianshu }}
                  </span>
                </template>
              </el-table-column>

              <!-- 状态：不同背景色 -->
              <el-table-column label="状态">
                <template #default="scope">
                  <span :class="{
                    'status-completed': scope.row.status === '完成',
                    'status-failed': scope.row.status === '失败',
                    'status-inprogress': scope.row.status === '进行中'
                  }">
                    {{ scope.row.status }}
                  </span>
                </template>
              </el-table-column>

              <el-table-column prop="describe" label="说明"></el-table-column>
              <el-table-column prop="create_time" label="时间"></el-table-column>
            </el-table>
            <div class="pagination" style="margin-top: 20px;">
              <el-pagination :current-page="currentPage" :page-size="pageSize" :total="totalRecords"
                layout="prev, pager, next, jumper" @current-change="handlePageChange">
              </el-pagination>
            </div>
          </div>
        </div>

        <!-- 积分充值部分 -->
        <div v-if="activeView === 'recharge'">
          <div class="recharge-form">
            <el-form ref="rechargeFormRef" :model="rechargeForm" class="recharge-form" label-position="top">
              <el-form-item label="激活码" prop="activationCode">
                <el-input v-model="rechargeForm.activationCode" placeholder="请输入激活码"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" style="background-color: #171717; border: none; border-radius: 5px;"
                  @click="confirmRecharge">
                  确认充值
                </el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>

        <!-- 修改密码 -->
        <div v-if="activeView === 'change-password'">
          <div class="change-password-form">
            <el-form ref="formRef" :model="form" :rules="formRules" class="change-password" label-position="top">
              <el-form-item label="当前密码" prop="oldPassword">
                <el-input v-model="form.oldPassword" type="password" placeholder="请输入当前密码"
                  @input="checkFormValidity"></el-input>
              </el-form-item>
              <el-form-item label="新的密码" prop="newPassword">
                <el-input v-model="form.newPassword" type="password" placeholder="请输入新密码"
                  @input="checkFormValidity"></el-input>
              </el-form-item>
              <el-form-item label="确认密码" prop="confirmPassword">
                <el-input v-model="form.confirmPassword" type="password" placeholder="确认新密码"
                  @input="checkFormValidity"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" :disabled="!isFormValid"
                  style="background-color: #171717; border: none; border-radius: 5px;" @click="updatePassword">
                  更新密码
                </el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios';
import config from '@/config.js';

export default {
  name: "ProfilePage",
  data() {
    return {
      activeView: "score-records", // 默认显示积分记录
      records: [],                 // 积分记录数组
      currentPage: 1,              // 当前页码
      pageSize: 5,                 // 每页显示 5 条记录
      totalRecords: 0,             // 总记录数
      form: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: ""
      },
      rechargeForm: {
        activationCode: ""
      },
      isFormValid: false
    };
  },
  computed: {
    // 将 Vuex 中的 account 映射为 userEmail
    ...mapState({
      userEmail: 'account'
    }),
    formRules() {
      return {
        oldPassword: [
          { required: true, message: "请输入当前密码", trigger: "blur" }
        ],
        newPassword: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          { min: 6, message: "密码长度不能小于6位", trigger: "blur" }
        ],
        confirmPassword: [
          { required: true, message: "请确认新密码", trigger: "blur" },
          { min: 6, message: "密码长度不能小于6位", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (value !== this.form.newPassword) {
                callback(new Error("两次密码不一致"));
              } else {
                callback();
              }
            },
            trigger: "blur"
          }
        ]
      };
    }
  },
  watch: {
    // 当切换到“积分记录”时调用接口更新记录
    activeView(newVal) {
      if (newVal === 'score-records') {
        this.currentPage = 1; // 切换到积分记录时重置页码
        this.fetchCostLogs();
      }
    }
  },
  mounted() {

    this.checkLoginStatus(); // 页面加载时检查登录状态

    if (this.activeView === 'score-records') {
      this.fetchCostLogs();
    }
  },
  methods: {

    // 检查用户是否登录
    checkLoginStatus() {
      const userId = this.$store.state.userId;
      if (!userId) {
        this.goToLogin();  // 如果没有 userId，跳转到登录页面
      }
    },

    // 跳转到登录页面
    goToLogin() {
      this.$router.push({ name: 'LoginPage' });
    },
    switchView(view) {
      this.activeView = view;
    },
    checkFormValidity() {
      if (this.$refs.formRef) {
        this.$refs.formRef.validate(valid => {
          this.isFormValid = valid;
        });
      }
    },
    updatePassword() {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          const payload = {
            account: this.userEmail,
            oldPassword: this.form.oldPassword,
            newPassword: this.form.newPassword,
            confirmPassword: this.form.confirmPassword
          };
          axios.post(`${config.BASE_URL}/pc/api/changePassword.php`, payload)
            .then(response => {
              const res = response.data;
              if (res.success) {
                this.$message.success(res.message || "密码更新成功");
                this.logoutHandler(); // 密码修改成功后直接退出登录
              } else {
                this.$message.error(res.error || res.message);
              }
            })
            .catch(error => {
              console.error("请求失败", error);
              this.$message.error("请求失败，请稍后再试");
            });
        } else {
          this.$message.error("表单验证失败，请检查输入");
          return false;
        }
      });
    },
    logoutHandler() {
      localStorage.removeItem('user');
      this.$store.dispatch('logout');
      this.$router.push({ name: 'LoginPage' });
    },
    confirmRecharge() {
      if (!this.rechargeForm.activationCode) {
        this.$message.error("请输入激活码");
        return;
      }
      const payload = {
        account: this.userEmail,
        inviteCode: this.rechargeForm.activationCode
      };
      axios.post(`${config.BASE_URL}/pc/api/rechargePoints.php`, payload)
        .then(response => {
          const res = response.data;
          if (res.success) {
            this.$message.success(res.message || "充值成功");
            this.rechargeForm.activationCode = "";
            // 充值成功后刷新积分记录（并可更新用户积分）
            this.fetchCostLogs();
          } else {
            this.$message.error(res.error || res.message);
          }
        })
        .catch(error => {
          console.error("充值请求失败", error);
          this.$message.error("充值请求失败，请稍后再试");
        });
    },
    fetchCostLogs() {
      // 构造请求数据：传递 account, page, pageSize
      const payload = {
        account: this.userEmail,
        page: this.currentPage,
        pageSize: this.pageSize
      };
      axios.post(`${config.BASE_URL}/pc/api/getCostLogs.php`, payload)
        .then(response => {
          const res = response.data;
          if (res.success) {
            this.records = res.data;   // 当前页记录数组
            this.totalRecords = res.total;  // 总记录数
          } else {
            this.$message.error(res.error || res.message);
          }
        })
        .catch(error => {
          console.error("获取积分记录失败", error);
          this.$message.error("获取积分记录失败，请稍后再试");
        });
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.fetchCostLogs();
    }
  }
};
</script>

<style scoped>
.profile-page {
  padding: 20px;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-info p {
  font-size: 16px;
}

.switch-buttons {
  display: flex;
  gap: 10px;
}

/* 自定义积分记录表格的样式 */
.records {
  margin-top: 30px;
}

/* 类型颜色 */
.type-recharge {
  color: green;
}

.type-consume {
  color: red;
}

/* 积分颜色 */
.points-positive {
  color: green;
}

.points-negative {
  color: red;
}

/* 状态背景色 */
.status-completed {
  background-color: #d4edda;
  /* 浅绿色背景 */
  color: #155724;
  padding: 2px 6px;
  border-radius: 4px;
}

.status-failed {
  background-color: #f8d7da;
  /* 浅红色背景 */
  color: #721c24;
  padding: 2px 6px;
  border-radius: 4px;
}

.status-inprogress {
  background-color: #fff3cd;
  /* 浅黄色背景 */
  color: #856404;
  padding: 2px 6px;
  border-radius: 4px;
}

/* 修改密码表单样式 */
.change-password {
  width: 300px;
}

/* 积分充值表单样式 */
.recharge-form {
  width: 300px;
}

/* 使用 :deep() 覆盖 Element UI 默认标签样式，让标签左对齐 */
.change-password :deep(.el-form-item__label),
.recharge-form :deep(.el-form-item__label) {
  text-align: left !important;
  width: 100%;
}

/* 调整输入框样式，使其更大 */
.change-password .el-input__inner,
.recharge-form .el-input__inner {
  font-size: 16px;
  padding: 10px;
  min-height: 40px;
}

/* 按钮宽度与输入框一致 */
.change-password .el-button,
.recharge-form .el-button {
  width: 100%;
}
</style>
