import { createStore } from 'vuex';

export default createStore({
  state: {
    isLoggedIn: false,  // 登录状态
    userDianshu: 0,     // 用户积分
    account: '',        // 用户账号
    userId: ''          // 用户ID
  },
  mutations: {
    setLoginStatus(state, status) {
      state.isLoggedIn = status;
    },
    setUserDianshu(state, dianshu) {
      state.userDianshu = dianshu;
    },
    setAccount(state, account) {
      state.account = account;
    },
    setUserId(state, userId) {
      state.userId = userId;
    }
  },
  actions: {
    login({ commit }, userData) {
      commit('setLoginStatus', true);
      commit('setUserDianshu', userData.dianshu);
      commit('setAccount', userData.account);
      commit('setUserId', userData.id); // 存储用户ID
      localStorage.setItem('user', JSON.stringify(userData)); // 将数据保存到 localStorage
    },
    logout({ commit }) {
      commit('setLoginStatus', false);
      commit('setUserDianshu', 0);
      commit('setAccount', '');
      commit('setUserId', ''); // 清空用户ID
      localStorage.removeItem('user'); // 清除本地存储
    }
  },
  getters: {
    isLoggedIn: (state) => state.isLoggedIn,
    userDianshu: (state) => state.userDianshu,
    account: (state) => state.account,
    userId: (state) => state.userId // 获取用户ID
  }
});
