// src/config.js

let BASE_URL;

if (process.env.NODE_ENV === 'development') {
  BASE_URL = 'https://dy.jzguai.com'; // 开发环境
} else {
  BASE_URL = window.location.origin; // 生产环境
}

export default {
  BASE_URL,
};
