import axios from 'axios';
import { ElMessage } from 'element-plus';
import config from '@/config.js'; // 引入配置文件
import { v4 as uuidv4 } from 'uuid';

/**
 * 获取上传签名
 * @param {string} type 文件类型（如 'video', 'image', 'audio', 'document' 等）
 * @returns {Promise<Object>} 返回签名数据
 */
export const getUploadSignature = async (type = 'file') => {
  try {
    const response = await axios.post(
      `${config.BASE_URL}/pc/api/upload_signature.php`,
      {
        type, // 上传类型
      },
      {
        headers: {
          'Content-Type': 'application/json', // 设置请求头
        },
      }
    );

    // 检查接口返回的数据
    if (response.data && response.data.success) {
      // 确保返回的数据包含所有必需的字段
      const requiredFields = ['accessKeyId', 'policy', 'signature', 'bucket', 'endpoint', 'folder'];
      for (const field of requiredFields) {
        if (!response.data[field]) {
          throw new Error(`接口返回数据缺少字段: ${field}`);
        }
      }

      // 返回签名数据
      return response.data;
    } else {
      // 如果接口返回 success 为 false，抛出错误
      throw new Error(response.data.error || "获取上传签名失败");
    }
  } catch (error) {
    console.error("获取上传签名失败:", error);
    throw error;
  }
};

/**
 * 上传文件到 OSS
 * @param {File} file 要上传的文件
 * @param {Object} signatureData 签名数据
 * @returns {Promise<{ success: boolean, url: string, size: number }>} 返回上传结果
 */
export const uploadToOSS = async (file, signatureData) => {
  return new Promise((resolve, reject) => {
    const uniqueFileName = `${uuidv4()}.${file.name.split('.').pop()}`; // 生成唯一的文件名

    const formData = new FormData();
    formData.append("key", `${signatureData.folder}${uniqueFileName}`); // 文件路径
    formData.append("policy", signatureData.policy); // 上传策略
    formData.append("OSSAccessKeyId", signatureData.accessKeyId); // AccessKeyId
    formData.append("signature", signatureData.signature); // 签名
    formData.append("success_action_status", "200"); // 上传成功时返回的状态码
    formData.append("file", file); // 文件

    axios.post(
      `https://${signatureData.bucket}.${signatureData.endpoint}`, // OSS 上传地址
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data", // 设置请求头
        },
      }
    )
      .then((response) => {
        if (response.status === 200) {
          // 上传成功
          const fileUrl = `https://${signatureData.bucket}.${signatureData.endpoint}/${signatureData.folder}${uniqueFileName}`;
          resolve({ success: true, url: fileUrl, size: file.size });
        } else {
          // 上传失败
          console.error("上传失败：", response);
          reject(new Error("上传失败"));
        }
      })
      .catch((error) => {
        console.error("上传失败：", error);
        reject(error);
      });
  });
};

/**
 * 上传文件前的校验
 * @param {File} file 要上传的文件
 * @param {Object} options 校验选项
 * @param {number} options.maxSize 文件最大大小（MB）
 * @returns {Promise<boolean>} 返回校验结果
 */
export const beforeUpload = async (file, options = {}) => {
  const { maxSize } = options;

  // 校验文件大小
  if (maxSize && file.size / 1024 / 1024 > maxSize) {
    ElMessage.error(`文件大小不能超过 ${maxSize}MB`);
    return false;
  }

  // 其他校验逻辑可以根据需要加上，例如文件类型、时长等
  return true;
};

/**
 * 获取视频/音频时长
 * @param {File} file 视频/音频文件
 * @returns {Promise<number>} 返回视频/音频时长（秒）
 */
export const getVideoDuration = (file) => {
  return new Promise((resolve, reject) => {
    const media = document.createElement(file.type.startsWith('video/') ? 'video' : 'audio');
    media.src = URL.createObjectURL(file);
    media.onloadedmetadata = () => {
      resolve(media.duration);
    };
    media.onerror = () => {
      reject(new Error("无法读取文件时长"));
    };
  });
};
