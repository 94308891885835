<template>
  <div id="app">
    <!-- 遮罩层 -->
    <div v-if="isMobile" class="mask">
      <div class="mask-message">
        暂未适配移动端，<br>请于电脑端使用，谢谢合作！
      </div>
    </div>

    <!-- 顶部导航栏 -->
    <el-header class="header">
      <div class="logo" @click="goToHome">
        <el-image :src="pclogo" alt="Logo" style="width: 200px; height: 50px;" />
      </div>
      <el-menu mode="horizontal" class="menu">
        <el-menu-item @click="goToHome" style="font-size:18px">分析视频</el-menu-item>
        <el-menu-item @click="goToHistory" v-if="isLoggedIn" style="font-size:18px">历史记录</el-menu-item>
        <el-menu-item @click="goToHSupper" v-if="isLoggedIn" style="font-size:18px">AI智能体</el-menu-item>
      </el-menu>
      <div class="user-info" v-if="isLoggedIn">
        <span style="font-size:18px;margin-right:5px">积分：{{ userDianshu }}</span>
        <el-button @click="goToProfile" class="mine-button">个人中心</el-button>
        <el-button @click="confirmLogout" class="exit-button">退出登录</el-button>
      </div>
      <el-button v-else @click="goToLogin" class="login-button">登录</el-button>
    </el-header>

    <!-- 主体内容区 -->
    <el-main>
      <router-view />
    </el-main>
  </div>
</template>

<script>
import { ElMessageBox, ElMessage } from 'element-plus'; // 导入 Element Plus 组件
import { mapState, mapActions } from 'vuex';
import axios from 'axios';
import config from "@/config.js"; // 导入配置文件

export default {
  data() {
    return {
      pointsInterval: null, // 定时器句柄
      pclogo: null, // Logo 图片
      isMobile: false, // 是否为移动端
    };
  },
  computed: {
    // 从 Vuex 获取登录状态、积分和账号
    ...mapState(['isLoggedIn', 'userDianshu', 'account']),
  },
  watch: {
    // 监听登录状态变化，动态管理积分定时器
    isLoggedIn(newVal) {
      if (newVal) {
        this.updatePoints();
        this.pointsInterval = setInterval(this.updatePoints, 100000);
      } else {
        clearInterval(this.pointsInterval);
        this.pointsInterval = null;
      }
    },
  },
  mounted() {
    this.checkDevice(); // 检查设备类型
    this.checkLoginStatus(); // 检查登录状态
    this.fetchConfig(); // 获取配置（标题、Logo、图标）
    window.addEventListener('resize', this.checkDevice); // 监听窗口大小变化
  },
  beforeUnmount() {
    // 组件卸载前清除定时器和事件监听
    if (this.pointsInterval) clearInterval(this.pointsInterval);
    window.removeEventListener('resize', this.checkDevice);
  },
  methods: {
    ...mapActions(['login', 'logout']),

    // 检查设备类型
    checkDevice() {
      const isMobileAgent = /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      const isSmallScreen = window.innerWidth <= 768; // 常见移动端断点
      this.isMobile = isMobileAgent || isSmallScreen;
    },

    // 检查登录状态
    checkLoginStatus() {
      const user = localStorage.getItem('user');
      if (user) {
        const userData = JSON.parse(user);
        this.login(userData); // 提交用户数据到 Vuex
      }
    },

    // 获取配置（标题、Logo、图标）
    async fetchConfig() {
      try {
        const response = await axios.post(`${config.BASE_URL}/pc/api/get_agent_info.php`, {
          agent_host: window.location.origin,
        });
        this.applyConfig(response.data);
      } catch (error) {
        ElMessage.error('配置加载失败');
      }
    },

    // 应用配置数据
    applyConfig(data) {
      if (data.pc_title) document.title = data.pc_title;
      if (data.pc_ico) this.setFavicon(data.pc_ico);
      if (data.pc_logo) this.pclogo = data.pc_logo;
    },

    // 设置浏览器图标
    setFavicon(iconUrl) {
      const link = document.querySelector('link[rel="icon"]') || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'icon';
      link.href = iconUrl;
      document.head.appendChild(link);
    },

    // 更新积分
    async updatePoints() {
      if (!this.account) {
        ElMessage.warning('账号信息缺失，无法获取积分');
        return;
      }
      try {
        const response = await axios.post(`${config.BASE_URL}/pc/api/getUserPoints.php`, {
          account: this.account,
        });
        if (response.data.success) {
          this.$store.commit('setUserDianshu', response.data.data.dianshu);
        } else {
          ElMessage.error(response.data.error || '积分获取失败');
        }
      } catch (error) {
        ElMessage.error('网络错误，请稍后重试');
      }
    },

    // 路由跳转方法
    goToHome() {
      this.$router.push({ name: 'HomePage' });
    },
    goToHistory() {
      this.$router.push({ name: 'HistoryPage' });
    },
    goToHSupper() {
      this.$router.push({ name: 'SupperPage' });
    },
    goToProfile() {
      this.$router.push({ name: 'ProfilePage' });
    },
    goToLogin() {
      this.$router.push({ name: 'LoginPage' });
    },

    // 退出登录
    confirmLogout() {
      ElMessageBox.confirm('确定退出登录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.logoutHandler();
        })
        .catch(() => {
          ElMessage.info('已取消退出');
        });
    },

    logoutHandler() {
      localStorage.removeItem('user');
      this.$store.dispatch('logout');
      this.$router.push({ name: 'LoginPage' });
    },
  },
};
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.9), rgba(12, 12, 12, 0.8));
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: glow 2s infinite alternate;
}

@keyframes glow {
  0% {
    background: rgba(12, 12, 12, 0.7);
  }
  100% {
    background: rgba(0, 0, 0, 0.85);
  }
}

.mask-message {
  color: white;
  font-size: 18px;
  text-align: center;
  padding: 20px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 8px;
}

.header {
  display: flex;
  justify-content: center; /* 使所有内容水平居中 */
  align-items: center;
  padding: 0px 20px;
  border-bottom: 2px solid #e8e8e8;
  background-color: #fff;
}

.logo {
  flex: 0 0 auto; /* 让 logo 按原本宽度显示 */
  cursor: pointer;
  margin-right: 20px; /* 给 logo 和菜单之间添加间距 */
  margin-left: 320px; /* 给 logo 和菜单之间添加间距 */
}

.menu {
  flex: 1; /* 让菜单部分占据剩余空间 */
  text-align: center; /* 让菜单项居中 */
}

.user-info {
  margin-left: 20px; /* 增加右边用户信息的间距 */
  display: flex;
  align-items: center;
  gap: 15px;
  margin-right: 320px;
}


.el-main {
  padding: 20px;
}

.login-button {
  background-color: rgb(37 99 235 / var(--tw-bg-opacity, 1));
  color: white;
  padding: 20px 18px;
  border-radius: 5px;
  font-size: 16px;
  margin-right: 360px;
}

.login-button:hover {
  background-color: rgb(37 99 235 / var(--tw-bg-opacity, 1));
}

.mine-button {
  padding: 20px 18px;
  border-radius: 5px;
  font-size: 16px;
}

.exit-button {
  background: linear-gradient(135deg, #6a11cb, #2575fc);
  color: white;
  padding: 20px 18px;
  border-radius: 5px;
  font-size: 16px;
}
</style>